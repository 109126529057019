<vp-availability-widget-input
	[className]="className()"
	[disabled]="hotels().length === 1"
	[excludeFromClosingRegex]="excludeFromClosingRegex()"
	[header]="'global.hotel' | translate"
	[iconName]="iconName()"
	[isOpen]="isOpen()"
	[label]="'global.hotel' | translate"
	[placeholder]="'global.hotel-selection-placeholder' | translate"
	[value]="selectedHotelName()"
	(emitIsOpen)="popoverStateChanged($event)"
	inputName="availability-hotels"
>
	<div class="flex max-h-[246px] flex-col overflow-y-scroll">
		@for (hotel of hotels(); track $index) {
			<button
				(click)="onSelectHotel(hotel)"
				class="btn btn-text w-full justify-start text-base font-normal hover:bg-secondary-75"
				data-test-class="hotels-selector-option"
			>
				{{ hotel.name }}
			</button>
		}
	</div>
</vp-availability-widget-input>
