import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import seedrandom, { PRNG } from 'seedrandom';

import { AlertType } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';
import { LabelPosition } from '@valk-nx/components/ui-label/src/lib/label.interface';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

import {
	HotelByDealCardInterface,
	SortOption,
	SortType,
} from './hotel-by-deal-card-interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-hotel-by-deal-card-list`,
	templateUrl: './hotel-by-deal-card-list.component.html',
})
export class HotelByDealCardListComponent implements OnInit {
	@Input() _editable: string;
	@Input() hotelbydeals: HotelByDealCardInterface[] = [];
	@Input() highlightedHotelSlug: string;

	get sortOptions(): SortOption[] {
		return [
			{
				value: 'NAME_ASC',
				label: this.translate.instant('global.sort.name-asc'),
			},
			{
				value: 'NAME_DESC',
				label: this.translate.instant('global.sort.name-desc'),
			},
			{
				value: 'PRICE_ASC',
				label: this.translate.instant('global.sort.price-asc'),
			},
			{
				value: 'PRICE_DESC',
				label: this.translate.instant('global.sort.price-desc'),
			},
		];
	}

	get orderedList() {
		return [...this.availableHotels, ...this.unAvailableHotels].filter(
			(hotel) =>
				hotel.normalizedName.toLowerCase().includes(this.searchText),
		);
	}

	get highlightedHotel(): HotelByDealCardInterface | undefined {
		if (this.highlightedHotelSlug !== undefined) {
			return this.hotelbydeals.find(
				(hotel) => hotel.hotelSlug === this.highlightedHotelSlug,
			);
		}
		return undefined;
	}

	labelPosition = LabelPosition.TopStacked;
	alertType: AlertType = 'dialog--warning';
	searchText = '';

	availableHotels: HotelByDealCardInterface[] = [];
	unAvailableHotels: HotelByDealCardInterface[] = [];
	randomNumberGenerator: PRNG;

	sortMap: Record<
		SortType,
		(a: HotelByDealCardInterface, b: HotelByDealCardInterface) => number
	> = {
		RANDOM: (_a, _b) => this.randomNumberGenerator() - 0.5,
		NAME_ASC: (a, b) => a.hotelName.localeCompare(b.hotelName),
		NAME_DESC: (a, b) => b.hotelName.localeCompare(a.hotelName),
		PRICE_ASC: (a, b) => a.price - b.price,
		PRICE_DESC: (a, b) => b.price - a.price,
	};

	constructor(
		private readonly translate: TranslateService,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {}

	ngOnInit() {
		this.hotelbydeals = this.hotelbydeals.map((hotel) => ({
			...hotel,
			normalizedName: GeneralHelper.getNormalizedName(hotel.hotelName),
		}));

		const seed = isPlatformBrowser(this.platformId)
			? this.getRandomSeedFromLocalStorage()
			: '1';

		this.randomNumberGenerator = seedrandom(seed);
		this.sortHotels('RANDOM');
	}

	getRandomSeedFromLocalStorage() {
		let randomSeed = sessionStorage.getItem('hotelListRandomSeed');

		if (!randomSeed) {
			randomSeed = GeneralHelper.generateRandomId();
			sessionStorage.setItem('hotelListRandomSeed', randomSeed);
		}

		return randomSeed;
	}

	sortHotels(sortType: string): void {
		this.availableHotels = this.hotelbydeals
			.filter((hotel) => hotel.price > 0)
			.sort(this.sortMap[sortType]);

		this.unAvailableHotels = this.hotelbydeals
			.filter((hotel) => hotel.price === 0)
			.sort(this.sortMap[sortType]);
	}

	setSearchText(value: unknown) {
		if (typeof value === 'string') {
			this.searchText = value
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
				.toLowerCase();
		}
	}
}
