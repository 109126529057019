/* eslint-disable @nx/enforce-module-boundaries */
import clipPathPlugin from 'tailwind-clip-path';
import type { Config } from 'tailwindcss';

import config from './libs/tailwind/src/configs/index';
import accordion from './libs/tailwind/src/plugins/accordion/accordion';
import breakpoint from './libs/tailwind/src/plugins/breakpoint/breakpoint';
import button from './libs/tailwind/src/plugins/button/button';
import card from './libs/tailwind/src/plugins/card/card';
import children from './libs/tailwind/src/plugins/children/children';
import divider from './libs/tailwind/src/plugins/divider/divider';
import elevation from './libs/tailwind/src/plugins/elevation/elevation';
import footer from './libs/tailwind/src/plugins/footer/footer';
import form from './libs/tailwind/src/plugins/form/form';
import headingGroup from './libs/tailwind/src/plugins/heading-group/heading-group';
import iconContainer from './libs/tailwind/src/plugins/icon-container/icon-container';
import inputControl from './libs/tailwind/src/plugins/input-control/input-control';
import list from './libs/tailwind/src/plugins/list/list';
import loyalCard from './libs/tailwind/src/plugins/loyal-card/loyal-card';
import notSelector from './libs/tailwind/src/plugins/not-selector/not-selector';
import overlay from './libs/tailwind/src/plugins/overlay/overlay';
import pagination from './libs/tailwind/src/plugins/pagination/pagination';
import pricing from './libs/tailwind/src/plugins/pricing/pricing';
import ribbon from './libs/tailwind/src/plugins/ribbon/ribbon';
import runningHead from './libs/tailwind/src/plugins/runninghead/runninghead';
import sectionSpacing from './libs/tailwind/src/plugins/section/section';
import table from './libs/tailwind/src/plugins/table/table';
import tag from './libs/tailwind/src/plugins/tag/tag';
import textBorder from './libs/tailwind/src/plugins/text-border/text-border';
import textLink from './libs/tailwind/src/plugins/text-link/text-link';
import base from './libs/tailwind/src/plugins/typography/base';
import spacings from './libs/tailwind/src/plugins/typography/spacings';
import typography from './libs/tailwind/src/plugins/typography/typography';

export default {
	content: ['src/**/*.{html,ts}'],
	theme: {
		extend: {
			animation: {
				'fade-in': 'fade-in 0.3s ease-out',
				loading: 'loading 2000ms infinite',
			},
			aspectRatio: {
				classic: '4 / 3',
				film: '3 / 2',
				anamorphic: '21 / 9',
				portrait: '2 / 3',
				'ultra-wide': '32 / 9',
			},
			borderRadius: {
				input: 'var(--inputs-border-radius, 0.25rem)',
				'tab-menu': 'var(--tab-menu-border-radius, 0)',
			},
			boxShadow: {
				border: '0 0 0 1px',
				'item-focus': '0px 0px 8px 0px',
				'inner-sm': 'inset 0px 2px 1px',
			},
			content: {
				empty: '""',
				unset: 'unset',
			},
			height: {
				'0%': '0%',
				inherit: 'inherit',
			},
			gap: {
				'content-section': 'var(--section-gap, 1rem)',
			},
			keyframes: {
				loading: {
					from: { backgroundPositionX: '100%' },
					to: { backgroundPositionX: '-100%' },
				},
				'fade-in': {
					'0%': { opacity: '0%' },
					'100%': { opacity: '100%' },
				},
			},
			margin: config.spacings,
			maxWidth: {
				'1/2': '50%',
			},
			padding: config.spacings,
			rotate: {
				135: '135deg',
				225: '225deg',
				315: '315deg',
			},
			screens: {
				xs: '320px',
				'max-md': { max: '767px' },
				xl: '1280px',
				'3xl': '1728px',
			},
			spacing: {
				18: '4.5rem',
			},
			textColor: config.colors.textColors,
			transitionProperty: {
				sheet: 'top, right, bottom, left, auto',
				spacing: 'margin, padding',
				width: 'width',
			},
			transitionTimingFunction: {
				basic: '0.4s ease',
			},
			width: {
				'1/10': '10%',
				'2/10': '20%',
				'3/10': '30%',
				'4/10': '40%',
				'5/10': '50%',
				'6/10': '60%',
				'7/10': '70%',
				'8/10': '80%',
				'9/10': '90%',
				inherit: 'inherit',
			},
		},
		colors: config.colors.colors,
		fontFamily: {
			'sans-serif': [
				"var(--font-family-sans-serif, 'Source Sans Pro')",
				'Arial',
				'sans-serif',
			],
			logo: [
				"var(--font-family-logo, 'Melbourne')",
				'Helvetica',
				'Arial',
				'sans-serif',
			],
			display: [
				"var(--font-family-display, 'Raleway')",
				'Helvetica',
				'Arial',
				'sans-serif',
			],
			extra: [
				"var(--font-family-extra, 'PT Sans')",
				'Arial',
				'sans-serif',
			],
		},
		fontSize: {
			xxs: [
				'var(--text-xxs-font-size, 0.625rem)',
				{ lineHeight: 'var(--text-xxs-line-height, 1)' },
			],
			xs: [
				'var(--text-xs-font-size, 0.75rem)',
				{ lineHeight: 'var(--text-xs-line-height, 1.625)' },
			],
			sm: [
				'var(--text-sm-font-size, 0.875rem)',
				{ lineHeight: 'var(--text-sm-line-height, 1.625)' },
			],
			base: [
				'var(--text-md-font-size, 1rem)',
				{ lineHeight: 'var(--text-md-line-height, 1.625)' },
			],
			lg: [
				'var(--text-lg-font-size, 1.125rem)',
				{ lineHeight: 'var(--text-lg-line-height, 1.5)' },
			],
			xl: [
				'var(--text-xl-font-size, 1.25rem)',
				{ lineHeight: 'var(--text-xl-line-height, 1.375)' },
			],
			'2xl': [
				'var(--text-2xl-font-size, 1.5rem)',
				{ lineHeight: 'var(--text-2xl-line-height, 1.25)' },
			],
			'3xl': [
				'var(--text-3xl-font-size, 1.875rem)',
				{ lineHeight: 'var(--text-3xl-line-height, 1.25)' },
			],
			'4xl': [
				'var(--text-4xl-font-size, 2.25rem)',
				{ lineHeight: 'var(--text-4xl-line-height, 1)' },
			],
			'5xl': [
				'var(--text-5xl-font-size, 2.5rem)',
				{ lineHeight: 'var(--text-5xl-line-height, 1)' },
			],
			'6xl': [
				'var(--text-6xl-font-size, 3rem)',
				{ lineHeight: 'var(--text-6xl-line-height, 1)' },
			],
			'7xl': [
				'var(--text-7xl-font-size, 3.75rem)',
				{ lineHeight: 'var(--text-7xl-line-height, 1)' },
			],
			'8xl': [
				'var(--text-8xl-font-size, 4.5rem)',
				{ lineHeight: 'var(--text-8xl-line-height, 1)' },
			],
			'9xl': [
				'var(--text-9xl-font-size, 6rem)',
				{ lineHeight: 'var(--text-9xl-line-height, 1)' },
			],
			inherit: ['inherit', { lineHeight: 'inherit' }],
		},
	},
	safelist: config.safelist,
	plugins: [
		clipPathPlugin,
		typography,
		children,
		notSelector,
		overlay,
		base,
		spacings,
		accordion,
		button,
		card,
		inputControl,
		elevation,
		divider,
		footer,
		form,
		list,
		loyalCard,
		ribbon,
		runningHead,
		textBorder,
		textLink,
		headingGroup,
		iconContainer,
		tag,
		pagination,
		pricing,
		sectionSpacing,
		breakpoint,
		table,
	],
} satisfies Config;
