<ng-container *ngrxLet="wrapper$ as globals">
	@if (globals?.banner; as banner) {
		@if (
			storyblokComponent !== 'globals' &&
			banner.component === 'banner' &&
			components
		) {
			<ndc-dynamic
				[ndcDynamicComponent]="components['banner']"
				[ndcDynamicInputs]="banner"
			/>
		}
	}
	<div class="relative flex h-screen flex-col">
		@if (globals?.header; as header) {
			@if (
				storyblokComponent !== 'globals' &&
				header.component === 'header' &&
				components
			) {
				<div
					#headerRef
					class="top-0 flex h-auto flex-col md:absolute md:w-full"
				>
					<ndc-dynamic
						[ndcDynamicComponent]="components['header']"
						[ndcDynamicInputs]="header"
					/>
				</div>
			}
		}
		<div class="grow">
			<ng-content />
		</div>
		@if (globals?.footer; as footer) {
			@if (
				storyblokComponent !== 'globals' &&
				footer.component === 'footer' &&
				components
			) {
				<ndc-dynamic
					[ndcDynamicComponent]="components['footer']"
					[ndcDynamicInputs]="footer"
				/>
			}
		}
	</div>

	@if (showCookieBanner) {
		<vp-cookie-manager
			[language]="globals?.language || fallbackLanguage"
			[translations]="cookieTranslations"
		/>
	}
</ng-container>
