<div
	[class]="className"
	[ngClass]="{ 'h-full': dynamicHeight }"
	class="relative w-full"
>
	<swiper-container
		#swiper
		[style.height]="height + 'px'"
		[style.width]="carouselItems[0]?.width + 'px' || 'auto'"
		class="grow-0 overflow-hidden sm:order-2 sm:mb-0"
		init="false"
	>
		@for (item of carouselItems; track item; let i = $index) {
			<swiper-slide class="cursor-grab" lazy="true">
				<img
					[alt]="item.altText"
					[height]="item.height"
					[ngClass]="{
						'bg-ui-25 object-none': !isCustomImages[i],
					}"
					[ngSrc]="
						item.src
							| imageQuery
								: { height: item.height, width: item.width }
					"
					[width]="item.width"
					(error)="setImageFallback(i)"
					class="size-full object-cover"
					data-test-class="carousel__image"
				/>
			</swiper-slide>
		}
	</swiper-container>
	@if (items.length > 1) {
		<div class="absolute left-0 top-1/2 z-10 size-11 translate-y-[-50%]">
			<button
				(click)="slidePrev()"
				aria-label="Previous slide"
				class="elevation-1 flex h-full w-inherit items-center justify-center rounded-r-full bg-[#000]/50 outline-2 outline-offset-2 outline-white focus:outline"
				data-test-id="carousel__prev-button"
				role="button"
				type="button"
			>
				<svg-icon
					class="h-auto w-4 text-white"
					src="assets/icons/icon-chevron-left.svg"
				/>
			</button>
		</div>
		<div class="absolute right-0 top-1/2 z-10 size-11 translate-y-[-50%]">
			<button
				(click)="slideNext()"
				aria-label="Next slide"
				class="elevation-1 flex h-full w-inherit items-center justify-center rounded-l-full bg-[#000]/50 outline-2 outline-offset-2 outline-white focus:outline"
				data-test-id="carousel__next-button"
				role="button"
				type="button"
			>
				<svg-icon
					class="inline-block h-auto w-4 text-white"
					src="assets/icons/icon-chevron-right.svg"
				/>
			</button>
		</div>
	}
</div>
