import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';

import { StorytellingComponent } from '@valk-nx/components/ui-storytelling/src/lib/storytelling';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { AnchorComponent } from '@valk-nx/storyblok/components/anchor/src/lib/anchor';
import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { HeadingGroupSectionComponent } from '@valk-nx/storyblok/components/heading-group-section/src/lib/heading-group-section';
import { RichTextComponent } from '@valk-nx/storyblok/components/richtext/src/lib/rich-text';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	ISbStoryBlok,
	Storytelling,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	button: AnchorComponent,
	headingGroupSection: HeadingGroupSectionComponent,
	richText: RichTextComponent,
};

@Component({
	selector: 'sb-storytelling-section',
	templateUrl: './storytelling-section.html',
	standalone: true,
	imports: [
		CommonModule,
		ColumnsComponent,
		DynamicModule,
		StorytellingComponent,
		StoryblokDirective,
	],
})
export class StoryblokStoryTellingComponent
	extends StoryblokRootDirective
	implements OnInit
{
	components: typeof Components = Components;
	@Input({ required: true }) title!: ISbStoryBlok[];
	@Input({ required: true }) storytellings!: Storytelling[];
	@Input() storytellingFullscreen: Storytelling[] | undefined;

	vpImages: PhotoInterface[] = [];
	vpImagesFullscreen: PhotoInterface[] = [];
	vpExtraImages: PhotoInterface[] = [];
	titleWithFigures: ISbStoryBlok[] = [];

	ngOnInit(): void {
		this.vpImages = this.setImages(this.storytellings, false);
		this.vpExtraImages = this.setImages(this.storytellings, true);
		this.vpImagesFullscreen = this.setImages(
			this.storytellingFullscreen,
			false,
		);
		this.setFigures();
	}

	setImages(
		storytellings: Storytelling[],
		extraImage: boolean,
	): PhotoInterface[] {
		const image = extraImage ? 'extraImage' : 'bynder';
		const imageAlt = extraImage ? 'extraImageAlt' : 'imageAlt';
		return storytellings?.map((storytelling) => {
			if (storytelling[image]?.length > 0) {
				return StoryblokHelper.mapBynderToPhotoInterface(
					storytelling[image][0],
					storytelling[imageAlt],
				);
			} else {
				return {
					src: '',
					alt: storytelling[imageAlt],
				};
			}
		});
	}

	setFigures(): void {
		this.titleWithFigures = this.title.map((title) => {
			return {
				...title,
				bottomFigure: 'assets/icons/figure-line.svg',
				topFigure: 'assets/icons/figure-toucan.svg',
			};
		});
	}
}
