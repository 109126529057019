import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-selection-card',
	template: `
		<div [attr.data-test-id]="field.id" class="my-3">
			<vp-selection-card
				[cardsAsMarkdown]="to['cardsAsMarkdown']"
				[customFormControl]="formControl"
				[disabled]="to.disabled"
				[grid]="to['grid']"
				[hasError]="showError"
				[initialValue]="formControl.value"
				[inputId]="field.id"
				[isValid]="formControl.valid && formControl.touched"
				[name]="field.key"
				[options]="to['options']"
				[readonly]="to.readonly"
				ngDefaultControl
			/>
		</div>
	`,
})
export class SelectionCardComponent extends FieldType {}
