<div
	[ngClass]="{
		'md:flex-row': !isVertical,
	}"
	class="flex flex-col flex-wrap items-start md:flex-row xl:flex-nowrap"
	data-test-id="usp-bar"
>
	@for (usp of usps; track usp) {
		<div
			[ngClass]="{
				'md:w-1/2 lg:w-1/3 xl:w-full': !isVertical,
			}"
			class="flex w-full flex-col p-2 xl:self-stretch"
			data-test-class="usp"
		>
			@if (usp.link) {
				<a
					[href]="usp.link"
					[ngClass]="{
						'gap-1 xl:flex-col xl:items-center xl:text-center':
							isStacked,
					}"
					[target]="usp.target || '_self'"
					class="flex flex-row gap-4 no-underline outline-black"
					rel="noopener noreferrer"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp }"
					/>
				</a>
			} @else {
				<div
					[ngClass]="{
						'gap-1 xl:flex-col xl:items-center xl:text-center':
							isStacked,
					}"
					class="flex gap-4"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp }"
					/>
				</div>
			}
		</div>
	}
</div>

<ng-template #uspTemplate let-usp="usp">
	<svg-icon
		[class]="iconVariant + ' ' + iconType"
		[ngClass]="{
			'icon-container-elevated': isElevatedIcon,
			'icon-container-lg-full': isStacked,
			'md:icon-container-lg': !isStacked,
		}"
		[src]="usp.icon"
		class="icon-container shrink-0"
	/>
	<div class="my-auto flex flex-grow flex-col">
		<span
			[ngClass]="{
				'text-dark-tertiary': iconVariant === 'default',
				'text-dark-primary': iconVariant !== 'default',
				'md:items-center md:justify-center': isStacked,
			}"
			class="flex flex-row font-sans-serif text-sm font-semibold md:text-base"
			data-test-id="usp-bar-title"
		>
			<span class="flex-grow">
				{{ usp.title | translate }}
			</span>
			@if (usp.link) {
				<svg-icon
					class="icon-container icon-container-sm ml-0 mt-0.5 flex-none md:mt-1"
					src="assets/icons/icon-chevron-right.svg"
				/>
			}
		</span>
		<span
			[ngClass]="
				iconVariant === 'default'
					? 'text-dark-tertiary'
					: 'text-dark-primary'
			"
			class="font-sans-serif text-sm md:text-base"
			data-test-id="usp-bar-description"
		>
			{{ usp.description | translate }}
		</span>
	</div>
</ng-template>
