import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import flatpickr from 'flatpickr';
import FlatpickrLanguages from 'flatpickr/dist/l10n';
import { Instance } from 'flatpickr/dist/types/instance';
import { BaseOptions } from 'flatpickr/dist/types/options';
import { filter, take, tap } from 'rxjs';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { FlatpickrFacade } from '@valk-nx/flatpickr-store/flatpickr.facade';

export interface FlatPickrEvent {
	selectedDates: Date[];
	dateString: string;
	instance: Instance;
	type: FlatPickrEventType;
}

export enum FlatPickrEventType {
	OnChange = 'onChange',
	OnOpen = 'onOpen',
	OnReady = 'onReady',
	OnValueUpdate = 'onValueUpdate',
}
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	selector: `vp-calendar`,
	styleUrls: ['calendar.scss'],
	templateUrl: './calendar.html',
})
export class CalendarComponent
	extends BaseDirective
	implements AfterViewInit, OnChanges
{
	@Input() calendarData: string[];
	@Input() config: Partial<BaseOptions> = {};
	@Input() isLoading = false;
	@Input() jumpToFirstAvailableDate = false;

	@Output() emitDates = new EventEmitter<Date[]>();
	@Output() emitMonthChange = new EventEmitter<string>();
	@Output() datePickerEmitter = new EventEmitter<FlatPickrEvent>();
	@Output() dayCreate = new EventEmitter<{
		dayElement: HTMLElement;
		instance: Instance;
	}>();
	@Output() emitHasError = new EventEmitter<boolean>();

	@ViewChild('calendar') calendar: ElementRef;

	datePicker: Instance;
	loadingDiv: HTMLDivElement;

	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly flatpickrFacade: FlatpickrFacade,
		public override translate: TranslateService,
	) {
		super(translate);
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.initFlatPickr();
			this.handleLoading();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (isPlatformBrowser(this.platformId)) {
			if (changes['calendarData']) {
				this.flatpickrFacade.redrawFlatpickr();
			}
			if (
				this.jumpToFirstAvailableDate &&
				changes['calendarData']?.currentValue
			) {
				const date = dayjs(
					changes['calendarData'].currentValue[0],
				).toDate();
				this.flatpickrFacade.jumpToDate(date);
			}

			if (
				changes['config']?.currentValue?.defaultDate !==
				changes['config']?.previousValue?.defaultDate
			) {
				this.jumpToDatestring(
					changes['config'].currentValue.defaultDate,
				);
			}
			this.handleLoading();
		}
	}

	jumpToDatestring(dateString) {
		const date = dayjs(dateString).toDate();
		this.flatpickrFacade.setDatesAndUpdateInCalendar([date]);
		this.flatpickrFacade.jumpToDate(date);
	}

	initFlatPickr() {
		this.flatpickrFacade.setInstance(
			flatpickr(this.calendar.nativeElement, {
				locale: { ...FlatpickrLanguages[this.language] },
				maxDate: dayjs().add(1, 'year').toDate(),
				minDate: dayjs().subtract(1, 'day').toDate(),
				monthSelectorType: 'static',
				inline: true,
				mode: 'single',
				...this.config,
				onChange: /* istanbul ignore next */ (
					selectedDates: Date[],
					dateString: string,
					instance: Instance,
				) => {
					this.datePickerEmitter.emit({
						type: FlatPickrEventType.OnChange,
						dateString,
						instance,
						selectedDates,
					});
				},
				onMonthChange: /* istanbul ignore next */ (
					_selectedDates: Date[],
					_dateString: string,
					instance: Instance,
				) => {
					this.handleMonthChange(instance);
				},
				onOpen: /* istanbul ignore next */ (
					selectedDates: Date[],
					dateString: string,
					instance: Instance,
				) => {
					this.datePickerEmitter.emit({
						type: FlatPickrEventType.OnOpen,
						dateString,
						instance,
						selectedDates,
					});
				},
				onReady: /* istanbul ignore next */ (
					selectedDates: Date[],
					dateString: string,
					instance: Instance,
				) => {
					this.datePickerEmitter.emit({
						type: FlatPickrEventType.OnReady,
						dateString,
						instance,
						selectedDates,
					});
				},
				onDayCreate: (
					_selectedDates: Date[],
					_dateString: string,
					instance: Instance,
					dayElement: HTMLElement,
				) => {
					this.dayCreate.emit({ dayElement, instance });
					this.handleDayCreate(dayElement);
				},
				onValueUpdate: /* istanbul ignore next */ (
					selectedDates: Date[],
					dateString: string,
					instance: Instance,
				) => {
					this.datePickerEmitter.emit({
						type: FlatPickrEventType.OnValueUpdate,
						dateString,
						instance,
						selectedDates,
					});
				},
			}),
		);
	}

	handleDayCreate(dayElement: HTMLElement) {
		if (
			dayElement['dateObj'] &&
			!dayElement.classList.contains('flatpickr-disabled') &&
			this.calendarData
		) {
			const date = dayjs(dayElement['dateObj']).format('YYYY-MM-DD');

			if (!this.calendarData.includes(date)) {
				dayElement.classList.add('flatpickr-disabled');
			}
		}
	}

	handleMonthChange(instance: Instance): void {
		const { currentMonth, currentYear } = instance;
		const newDate = dayjs(`${currentYear}-${currentMonth + 1}-01`);
		const minDate = dayjs(instance.config.minDate).add(1, 'day');

		if (!dayjs(minDate).isBefore(newDate)) {
			this.emitMonthChange.emit(minDate.format('YYYY-MM-DD'));
			return;
		}
		this.emitMonthChange.emit(newDate.format('YYYY-MM-DD'));
	}

	private createLoadingElements(): void {
		this.loadingDiv = document.createElement('div');
		this.loadingDiv.classList.add('loading-container');
		this.loadingDiv.setAttribute('id', 'is-loading');

		const loadingSVG = document.createElement('div');
		loadingSVG.classList.add('loading-svg');
		this.loadingDiv.append(loadingSVG);
	}

	handleLoading(): void {
		this.flatpickrFacade.selectInstance$
			.pipe(
				take(1),
				filter((instance) => !!instance),
				tap((instance) => {
					if (this.isLoading) {
						if (!this.loadingDiv) {
							this.createLoadingElements();
						}

						instance.calendarContainer.classList.add('is-loading');
						instance.innerContainer.append(this.loadingDiv);
					} else {
						instance.calendarContainer.classList.remove(
							'is-loading',
						);

						if (this.loadingDiv) {
							this.loadingDiv.remove();
						}
					}
				}),
			)
			.subscribe();
	}
}
