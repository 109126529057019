import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom, map, switchMap, take } from 'rxjs';

import { AlertService } from '@valk-nx/components/ui-alert/src/lib/services/alert.service';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';

import { alertErrorSettings } from '../globals';

export const GIFTCARD_SERVICE_URL = new InjectionToken<string>(
	'giftcard-service-url',
);

export const GIFTCARD_API_COOKIES = new InjectionToken<string[]>(
	'giftcard_api-cookies',
);

export const POLLING_TIME = new InjectionToken<string[]>('polling_time');

export interface GiftcardCustomer {
	salutation: string;
	firstName: string;
	insertion: string;
	lastName: string;
	countryCode: string;
	postalCode: string;
	houseNumber: string;
	houseNumberAddition?: string;
	street: string;
	city: string;
	phoneNumber: string;
	emailAddress: string;
}

export interface GiftcardBusinessCustomer {
	firstName: string;
	insertion: string;
	lastName: string;
	phoneNumber: string;
	emailAddress: string;
	companyName: string;
}

export interface GiftcardOrderSummary {
	cards: GiftcardPriceSummary;
	packaging: GiftcardPriceSummary;
	administration: GiftcardPriceSummary;
	totalIncl: number;
	totalExcl: number;
	taxes: number;
}

export interface GiftcardPriceSummary {
	amount: number;
	price: number;
	taxPercentage: number;
	totalIncl: number;
	totalExcl: number;
}

export interface GiftcardCreateOrderResponse {
	redirectURL: string;
	orderGUID: string;
}

export interface GiftcardBalanceResponse {
	balance: number;
	status: string;
}

@Injectable({ providedIn: 'root' })
export class GiftcardService {
	constructor(
		private readonly translate: TranslateService,
		private readonly alertService: AlertService,
		private readonly http: HttpClient,
		@Optional()
		@Inject(GIFTCARD_SERVICE_URL)
		private readonly serviceUrl: string,
		private readonly recaptchaV3Service: ReCaptchaV3Service,
		@Inject(GIFTCARD_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
	) {}

	activateCard(cardNumber: string, activationCode: string): Promise<boolean> {
		const cardNumberStripped = cardNumber.replace(/-/g, '');

		return firstValueFrom(
			this.recaptchaV3Service.execute('activateCard').pipe(
				switchMap((token) => {
					return this.http.post<{ data: { success: boolean } }>(
						`${this.serviceUrl}giftcard/activate-card`,
						{
							cardNumber: cardNumberStripped,
							activationCode,
							token,
						},
						{
							...this.apiCookies,
						},
					);
				}),
				take(1),
				map((body) => body.data.success),
			),
		).catch((error) => {
			if (error.status >= 500 || error.status === 0) {
				this.alertService.error({
					...alertErrorSettings,
					content: this.translate.instant('global.service.error'),
				});
			}
			return Promise.reject(new Error(error));
		});
	}

	checkBalance(
		cardNumber: string,
		pinCode: string,
	): Promise<GiftcardBalanceResponse> {
		const cardNumberStripped = cardNumber.replace(/-/g, '');

		return firstValueFrom(
			this.recaptchaV3Service.execute('checkBalance').pipe(
				switchMap((token) => {
					return this.http.post<{
						data: GiftcardBalanceResponse;
					}>(
						`${this.serviceUrl}giftcard/check-balance`,
						{ cardNumber: cardNumberStripped, pinCode, token },
						{
							...this.apiCookies,
						},
					);
				}),
				take(1),
				map((body) => body.data),
			),
		).catch((error) => {
			if (error.status >= 500 || error.status === 0) {
				this.alertService.error({
					...alertErrorSettings,
					content: this.translate.instant('global.service.error'),
				});
			}
			return Promise.reject(new Error(error));
		});
	}

	getOrderSummary(
		amount: number,
		value: number,
		cardType: string,
		countryCode: string,
		isEGiftcard?: boolean,
	): Promise<GiftcardOrderSummary> {
		const summaryCountryCode = isEGiftcard ? 'NL' : countryCode; // For e-giftcards we always use NL as the country code

		return firstValueFrom(
			this.http
				.post<{ data: GiftcardOrderSummary }>(
					`${this.serviceUrl}order/summary`,
					{
						amount,
						value,
						cardType,
						countryCode: summaryCountryCode,
					},
					{
						...this.apiCookies,
					},
				)
				.pipe(
					take(1),
					map((body) => body.data),
				),
		).catch((error) => {
			this.alertService.error({
				...alertErrorSettings,
				content: this.translate.instant('global.service.error'),
			});
			return Promise.reject(new Error(error));
		});
	}

	createOrder(
		amount: number,
		value: number,
		cardType: string,
		customer: GiftcardCustomer | undefined,
		recipient:
			| Omit<GiftcardCustomer, 'phoneNumber'>
			| undefined = undefined,
		isEGiftcard?: boolean,
		message?: string,
		redirectURL?: string,
	): Promise<GiftcardCreateOrderResponse> {
		const orderCustomer = !isEGiftcard
			? customer
			: {
					...customer,
					countryCode: 'NL',
					postalCode: 'FAKE',
					houseNumber: 'FAKE',
					street: 'FAKE',
					city: 'FAKE',
				};
		const orderRecipient = !isEGiftcard
			? recipient
			: recipient?.firstName
				? {
						...recipient,
						countryCode: ' ',
						city: ' ',
						postalCode: ' ',
						street: ' ',
						houseNumber: ' ',
						houseNumberAddition: ' ',
					}
				: undefined;

		return firstValueFrom(
			this.http
				.post<{ data: GiftcardCreateOrderResponse }>(
					`${this.serviceUrl}order/create`,
					{
						amount,
						value,
						cardType,
						customer: orderCustomer,
						redirectURL,
						recipient: orderRecipient,
						message,
					},
				)
				.pipe(
					take(1),
					map((body) => body.data),
				),
		).catch((error) => {
			this.alertService.error({
				...alertErrorSettings,
				content: this.translate.instant('global.service.error'),
			});
			return Promise.reject(new Error(error));
		});
	}

	createBusinessOrder(
		customer: GiftcardBusinessCustomer,
		request: string,
	): Promise<{
		success: boolean;
	}> {
		return firstValueFrom(
			this.recaptchaV3Service.execute('orderBusiness').pipe(
				switchMap((token) => {
					return this.http.post<{ data: { success: true } }>(
						`${this.serviceUrl}order/business`,
						{
							firstName: customer.firstName,
							insertion: customer.insertion,
							lastName: customer.lastName,
							phoneNumber: customer.phoneNumber,
							emailAddress: customer.emailAddress,
							companyName: customer.companyName,
							request,
							token,
						},
						{
							...this.apiCookies,
						},
					);
				}),
				take(1),
				map((body) => {
					return {
						success: body.data.success,
					};
				}),
			),
		).catch(() => {
			this.alertService.error({
				...alertErrorSettings,
				content: this.translate.instant('global.service.error'),
			});
			return { success: false };
		});
	}

	getOrderStatus(orderGUID: string, id: number): Promise<string> {
		const headers = new HttpHeaders();
		// NOTE: we use the id to create a unique http GET call due to a bug in hydration, remove the retries when hydration cache is customizable (https://github.com/angular/angular/issues/50117)
		const retries = id ? `?retries=${id}` : '';
		return firstValueFrom(
			this.http
				.get<HALResponse<{ status: string }>>(
					`${this.serviceUrl}order/${orderGUID}/status${retries}`,
					{
						...this.apiCookies,
						headers,
						observe: 'response',
					},
				)
				.pipe(
					take(1),

					map((res) => res.body!.data.status),
				),
		).catch((error) => {
			this.alertService.error({
				...alertErrorSettings,
				content: this.translate.instant('global.service.error'),
			});
			return Promise.reject(new Error(error));
		});
	}
}
