<section
	[editableInStoryblok]="_editable"
	class="section-gap"
	data-test-class="storytelling-section"
>
	<div
		[ngClass]="{ 'pb-0': storytellingFullscreen?.length > 0 }"
		class="section-spacing section-gap"
	>
		<sb-columns [colOne]="titleWithFigures" component="columnOne" />
		<div class="section-gap">
			@for (
				storytelling of storytellings;
				track storytelling;
				let index = $index,
				odd = $odd
			) {
				<ng-container
					[ngTemplateOutlet]="storytellingRef"
					[ngTemplateOutletContext]="{
						extraImage: vpExtraImages[index],
						image: vpImages[index],
						isFullscreen: false,
						storytelling: storytelling,
						position: odd ? 'left' : 'right',
					}"
				/>
			}
		</div>
	</div>
	@if (storytellingFullscreen?.length > 0) {
		<div class="section-spacing section-gap pt-0 lg:px-0">
			@for (
				storytelling of storytellingFullscreen;
				track storytelling;
				let index = $index
			) {
				<ng-container
					[ngTemplateOutlet]="storytellingRef"
					[ngTemplateOutletContext]="{
						extraImage: vpExtraImages[index],
						image: vpImages[index],
						isFullscreen: true,
						storytelling: storytelling,
						position:
							storytellings.length % 2 !== 0 ? 'left' : 'right',
					}"
				/>
			}
		</div>
	}
</section>

<ng-template
	#storytellingRef
	let-extraImage="extraImage"
	let-image="image"
	let-isFullscreen="isFullscreen"
	let-position="position"
	let-storytelling="storytelling"
>
	<vp-storytelling
		[editableInStoryblok]="storytelling._editable"
		[extraImage]="extraImage"
		[image]="image"
		[isFullscreen]="isFullscreen"
		[position]="position"
	>
		<div class="flex flex-col gap-4">
			<header>
				<h2 class="heading-group">
					{{ storytelling.title }}
				</h2>
				<p class="heading-group-overline" role="doc-subtitle">
					{{ storytelling.overline }}
				</p>
			</header>

			<div class="flex flex-col gap-4">
				@for (blok of storytelling.content; track $index) {
					@if (components) {
						<ndc-dynamic
							[ndcDynamicComponent]="
								components[blok['component']]
							"
							[ndcDynamicInputs]="blok"
							class="hidden"
						/>
					}
				}
			</div>
		</div>
	</vp-storytelling>
</ng-template>
