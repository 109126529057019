import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	PLATFORM_ID,
	ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	filter,
	map,
	Observable,
	of,
	ReplaySubject,
	switchMap,
} from 'rxjs';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

import { DropdownOption } from './dropdown.interface';
import { equalDropDownOptions } from './dropdown-options';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	exportAs: 'vpDropdown',
	standalone: true,
	selector: `vp-dropdown`,
	styleUrls: ['./scss/dropdown.scss'],
	templateUrl: './dropdown.html',
	imports: [
		AngularSvgIconModule,
		CommonModule,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
	],
})
export class DropdownComponent
	extends BaseDirective
	implements OnInit, OnChanges, AfterViewChecked
{
	@Input() className = '';
	@Input() hasError = false;
	@Input() isValid = false;
	@Input() isRequired = false;
	@Input() centered = false;
	@Input({ required: true }) selectData: DropdownOption[] = [];
	_placeholder$ = new BehaviorSubject<string>(
		'components.dropdown.placeholder',
	);
	@Input() set placeholder(value: string) {
		this._placeholder$.next(value);
	}
	@Input() variant: 'attached' | 'integrated' | 'detached' = 'attached';
	@Input({ required: true }) value!: string;
	@Input() inputId = '';
	@Input() label = '';
	@Input() formControl = new FormControl();
	@Input() searchable = false;
	@Input() useAutoComplete = true;
	@Input() dropdownPosition: DropdownPosition = 'bottom';
	@Input() isWhite = false;
	@Input() useAsFormControl = false;

	@Output() selectedValue = new EventEmitter<string>();

	translatedPlaceholder$!: Observable<string>;
	selectOptions$ = new Observable<DropdownOption[]>();
	private readonly selectData$ = new ReplaySubject<DropdownOption[]>();
	selectedDropdownPlaceholder$!: Observable<string>;

	constructor(
		public override translate: TranslateService,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {
		super(translate);
	}

	ngOnInit(): void {
		this.selectOptions$ = this.selectData$.pipe(
			distinctUntilChanged(equalDropDownOptions),
			filter((options) => !!options),
			switchMap((options: DropdownOption[]) => {
				if (options.length === 0) {
					return of([]);
				}
				const optionLabels = options.map((option) => option.label);
				const translatedLabels$ = this.translate
					.stream(optionLabels)
					.pipe(
						map((translations) => {
							return options.map((option) => {
								return {
									...option,
									label: translations[option.label],
								};
							});
						}),
					);
				const selectedLabels = options.map(
					(option) => option.selectLabel,
				);

				const translatedSelectLabel$ = this.translate
					.stream(selectedLabels as string[])
					.pipe(
						map((translations) => {
							return options.map((option) => {
								return {
									...option,
									selectLabel:
										translations[
											option.selectLabel as string
										],
								};
							});
						}),
					);
				return combineLatest([
					translatedLabels$,
					translatedSelectLabel$,
				]).pipe(
					map(([labels, selectLabels]) => {
						return options.map((option, index) => {
							return {
								...option,
								label: labels[index].label,
								selectLabel: selectLabels[index].selectLabel,
							};
						});
					}),
				);
			}),
		);

		this.translatedPlaceholder$ = this._placeholder$.pipe(
			switchMap((placeholder) => {
				return placeholder
					? this.translate.stream(placeholder)
					: of('');
			}),
		);

		this.selectedDropdownPlaceholder$ = this.selectOptions$.pipe(
			map((options) => {
				const selectedValue = options.find(
					(option) => option.value === this.value,
				)?.label;
				return selectedValue || this.value;
			}),
		);
	}

	ngAfterViewChecked() {
		if (
			isPlatformBrowser(this.platformId) &&
			!this.useAutoComplete &&
			document.getElementById(this.inputId)
		) {
			// Ng-select doesnt allow to change autocomplete property on the input
			document
				.getElementById(this.inputId)!
				.setAttribute('autocomplete', 'not-autocomplete');
		}
	}

	ngOnChanges(): void {
		this.selectData$.next(this.selectData);
	}

	update() {
		this.selectedValue.emit(this.value);
	}
}
