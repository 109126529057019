import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	ExperimentalPendingTasks,
	Inject,
	Injectable,
	InjectionToken,
	Injector,
	Optional,
	PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
	ISbStoriesParams,
	ISbStoryData,
	SbSDKOptions,
	storyblokInit,
	useStoryblokBridge,
} from '@storyblok/js';
import dayjs from 'dayjs';
import { Response } from 'express';
import { from, map, Observable, shareReplay, switchMap, take } from 'rxjs';
import StoryblokClient from 'storyblok-js-client';

import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import {
	fallbackLanguage,
	Language,
	languagesLabels,
} from '@valk-nx/core/lib/core';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokParamsService } from '@valk-nx/storyblok-services/src/lib/services/storyblok-params.service';
import {
	Banner,
	Footer,
	ISbComponent,
	ISbStoryLinks,
	PageMetaData,
	Redirect,
	SbFooterMenu,
	StoryblokGlobals,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

export const STORYBLOK_ACCESS_TOKEN = new InjectionToken<string[]>(
	'storyblok-access-token',
);

export const STORYBLOK_ENDPOINT = new InjectionToken<string[]>(
	'storyblok-endpoint',
);
export const RESPONSE = new InjectionToken<Response>('response');

@Injectable({
	providedIn: 'root',
})
export class StoryblokService {
	storyblokBridge = useStoryblokBridge;
	public sbClient: StoryblokClient | undefined;

	public readonly translatedSlugs$: Observable<TranslatedSlug[]>;
	public readonly story$: Observable<ISbStoryData>;
	public readonly storyByUUID$: (uuid: string) => Observable<ISbStoryData>;
	public readonly globals$: Observable<StoryblokGlobals>;

	constructor(
		@Inject(STORYBLOK_ACCESS_TOKEN) private readonly token: string,
		@Optional()
		@Inject(STORYBLOK_ENDPOINT)
		private readonly endpoint: string,
		storyblokParamsService: StoryblokParamsService,
		translateService: TranslateService,
		private readonly metadataService: MetadataService,
		private readonly router: Router,
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) public platformId: string,
		@Inject(Injector) private readonly injector: Injector,
		@Optional() @Inject(RESPONSE) private readonly response: Response,
		private readonly pendingTasks: ExperimentalPendingTasks,
	) {
		this.sbClient = storyblokInit({
			accessToken: this.token,
			use: [
				// This is a modified ApiFactory, just to be able to alter the endpoint parameter in the
				// StoryblokClient
				(options: SbSDKOptions) => {
					const { apiOptions } = options;
					/* istanbul ignore next */
					if (!apiOptions?.accessToken) {
						console.error(
							'You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication',
						);
						return {};
					}
					const storyblokApi = new StoryblokClient(
						{
							...apiOptions,
							cache: { clear: 'auto', type: 'memory' },
						},
						this.endpoint,
					);
					return { storyblokApi };
				},
			],
		}).storyblokApi;

		// Required to disable throttling in the Storyblok client
		// @ts-expect-error private property is overridden to prevent throttling issue
		this.sbClient.throttle = this.sbClient.throttledRequest;

		this.story$ = storyblokParamsService.storyblokParams$.pipe(
			switchMap(({ slug, isDraft, language }) => {
				translateService.use(language);
				dayjs.locale(language);
				this.document.documentElement.lang = language;
				return from(
					this.getStory(slug, isDraft, language).catch(() => {
						this.checkRedirect(slug);

						// Set the document status to 404 because from here the error-404 page will be served
						if (isPlatformServer(this.platformId)) {
							this.response.status(404);
						}
						return this.getStory('error-404', isDraft, language);
					}),
				).pipe(
					switchMap((storyblokData) => {
						const data = StoryblokHelper.mapLanguageUrlSlug(
							storyblokData.data.story,
							storyblokData.data.links,
							language,
						);
						const content =
							data.content as unknown as PageMetaData & {
								component: string;
							};

						if (content.metaTitle) {
							this.metadataService.updateMetadata(content);
						}

						this.metadataService.createLinkForCanonicalURL(
							data.content['canonical'],
						);

						if (data.content['content']) {
							StoryblokHelper.checkNegativeHeader(
								data.content['content'][0],
							);
						}

						return new Observable<ISbStoryData>((observer) => {
							observer.next(data);
							if (
								data &&
								isDraft &&
								isPlatformBrowser(this.platformId)
							) {
								/* istanbul ignore next */
								// Listen to Storyblok Visual Editor events https://github.com/storyblok/storyblok-js#2-listen-to-storyblok-visual-editor-events
								this.storyblokBridge(
									data.id,
									(bridgeData) => {
										observer.next(bridgeData);

										// Scroll to the target inside the Visual Editor after changes have been 'pushed'
										setTimeout(() => {
											GeneralHelper.scrollToElementWithId(
												'storyblok__overlay',
												200,
												false,
											);
										}, 100);
									},
									{ preventClicks: true },
								);
							}
						});
					}),
				);
			}),
			shareReplay(1),
		);

		this.translatedSlugs$ = this.story$.pipe(
			map((storyblokData) => {
				const currentLang = storyblokData.lang;

				const defaultLanguageMenuItem = {
					path: `${storyblokData['default_full_slug']}`,
					name: languagesLabels[
						fallbackLanguage.toLowerCase() as Language
					],
					lang: fallbackLanguage.toUpperCase() as Language,
					selected: fallbackLanguage === currentLang,
				};
				const languageMenuItems =
					storyblokData['translated_slugs']?.map((slug) => {
						return {
							path: `${slug.lang}/${slug.path}`,
							name: languagesLabels[
								slug.lang.toLowerCase() as Language
							],
							lang: slug.lang.toUpperCase() as Language,
							selected: slug.lang === currentLang,
						};
					}) || [];

				const allSlugs = [
					defaultLanguageMenuItem,
					...languageMenuItems,
				];

				this.metadataService.setHrefLang(allSlugs, fallbackLanguage);

				return allSlugs;
			}),
		);

		this.globals$ = storyblokParamsService.storyblokParams$.pipe(
			switchMap(({ isDraft, language }) => {
				return from(this.getStory('globals', isDraft, language)).pipe(
					map((storyblokData) => {
						const { content } = storyblokData.data.story;

						const storyData = StoryblokHelper.mapLanguageUrlSlug(
							content['content'],
							storyblokData.data.links,
							language,
						) as unknown as ISbComponent[];

						const bannerData = content[
							'banner'
						] as unknown as Banner[];

						const banner = bannerData?.find(
							(x) => x.component === 'banner',
						);

						const header = storyData.find(
							(x) => x.component === 'header',
						);

						const footer = storyData.find(
							(x) => x.component === 'footer',
						) as ISbComponent & Footer;
						const footerMenu = StoryblokHelper.mapFooterMenu(
							footer?.menu as unknown as SbFooterMenu[],
						);
						const redirects = storyData.find(
							(x) => x.component === 'redirects',
						) as ISbComponent & { redirect: Redirect[] };

						return {
							header,
							banner,
							footer: { ...footer, footerMenu },
							redirects,
							language: fallbackLanguage,
						};
					}),
					shareReplay(1),
				);
			}),
		);

		this.storyByUUID$ = (uuid: string) =>
			storyblokParamsService.storyblokParams$.pipe(
				switchMap(({ isDraft, language }) => {
					return from(
						this.getStory(uuid, isDraft, language, {
							find_by: 'uuid',
						}),
					).pipe(
						map((storyblokData) =>
							StoryblokHelper.mapLanguageUrlSlug(
								storyblokData.data.story,
								storyblokData.data.links,
								language,
							),
						),
					);
				}),
			);
	}

	checkRedirect(slug: string): void {
		this.globals$.pipe(take(1)).subscribe((sbData) => {
			const redirect: Redirect | undefined =
				sbData.redirects?.redirect.find(
					(redirect) =>
						redirect.fromUrl.toLowerCase() ===
						`/${slug.toLowerCase()}`,
				);
			if (redirect) {
				if (isPlatformServer(this.platformId)) {
					const response = this.injector.get(RESPONSE);
					response.status(+redirect.status);
					response.location(redirect.toUrl);
				} else if (isPlatformBrowser(this.platformId)) {
					this.router.navigateByUrl(redirect.toUrl);
				}
			}
		});
	}

	getStory(
		slug: string,
		isDraft: boolean,
		language: Language = fallbackLanguage,
		params?: Record<string, string>,
	): Promise<ISbStoryLinks> {
		// this forces the SSR to wait until the task is done, as the
		// storyblok sdk doesn't use the Angular HttpClient
		const taskDone = this.pendingTasks.add();
		return (this.sbClient as StoryblokClient)
			.get(`cdn/stories/${slug}`, {
				...params,
				resolve_links: 'link',
				version: isDraft ? 'draft' : 'published',
				language: language,
			})
			.finally(() => taskDone());
	}

	getStoriesByQuery(
		query: Partial<ISbStoriesParams>,
		language: Language = fallbackLanguage,
	): Promise<ISbStoryLinks> {
		// this forces the SSR to wait until the task is done, as the
		// storyblok sdk doesn't use the Angular HttpClient
		const taskDone = this.pendingTasks.add();
		return (this.sbClient as StoryblokClient)
			.get(`cdn/stories`, {
				...query,
				resolve_links: 'link',
				version: 'published',
				language: language,
			})
			.finally(() => taskDone());
	}
}
