import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { DynamicModule, InputsType } from 'ng-dynamic-component';
import { combineLatest, from, map, Observable, switchMap } from 'rxjs';

import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { AlertType } from '@valk-nx/components/ui-alert/src/lib/contracts/alert.types';
import { IconGridComponent } from '@valk-nx/components/ui-icon-grid/src/lib/icon-grid';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { HOTEL_SLUG, Language } from '@valk-nx/core/lib/core';
import { CarouselComponent as SbCarouselComponent } from '@valk-nx/storyblok/components/carousel/src/lib/carousel';
import { SectionComponent } from '@valk-nx/storyblok/components/section/src/lib/section';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import type {
	MeetingRoomData,
	MeetingRoomResponseData,
} from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { ISbStoryBlok } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

const Components = {
	carousel: SbCarouselComponent,
	section: SectionComponent,
};

@Component({
	selector: 'sb-meeting-room-overview',
	templateUrl: './meeting-room-overview.component.html',
	standalone: true,
	imports: [
		AlertComponent,
		CommonModule,
		DynamicModule,
		IconGridComponent,
		PersuadeCardComponent,
		TranslateModule,
	],
})
export class MeetingRoomOverviewComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: ISbStoryBlok[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;

	get contentInputs(): (InputsType & { component: string })[] {
		return this.content.map((blok) => ({
			...blok,
			component: blok['component'] || '',
		}));
	}

	components: typeof Components = Components;
	currentUrl = '';
	meetingRooms$: Observable<MeetingRoomData[]>;
	alertType: AlertType = 'dialog--warning';

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private readonly router: Router,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();

		this.meetingRooms$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				this.currentUrl = this.router.url;

				const selectedSlug =
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0];
				const path =
					selectedSlug.lang.toLowerCase() !== 'nl'
						? selectedSlug.path.substring(3)
						: selectedSlug.path;
				return {
					path,
					language: selectedSlug.lang.toLowerCase() as Language,
				};
			}),
			switchMap(({ path, language }) =>
				combineLatest([
					from(
						this.sbService.getStoriesByQuery(
							{
								starts_with: path,
								excluding_slugs: path,
								per_page: 50,
							},
							language,
						),
					),
					this.contentService.getMeetingRooms(language),
				]),
			),
			map(([storiesResponse, meetingRoomsResponse]) => {
				const storyblokMeetingRooms = storiesResponse.data.stories;
				const availableMeetingRooms = meetingRoomsResponse.body.data;

				const meetingRooms = availableMeetingRooms.filter(
					(availableMeetingRoom) =>
						storyblokMeetingRooms.some(
							(storyblokMeetingRoom) =>
								storyblokMeetingRoom.full_slug
									.split('/')
									.pop() === availableMeetingRoom.slug,
						),
				);

				return this.buildLayoutGrid(meetingRooms);
			}),
		);
	}

	buildLayoutGrid(
		meetingRooms: MeetingRoomResponseData[],
	): MeetingRoomData[] {
		return meetingRooms.map((meetingRoom) => {
			return {
				...meetingRoom,
				layouts: meetingRoom.layouts.map((layout) => {
					return {
						icon: `assets/icons/layout-${layout.icon}.svg`,
						title: layout.name,
						subtitle:
							layout.maxPersons === 0
								? undefined
								: layout.maxPersons.toString(),
					};
				}),
			};
		});
	}
}
