import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { LinkModule } from '@valk-nx/core/lib/directives/link/link.module';

import { Link } from './link.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CommonModule, RouterModule, LinkModule],
	selector: 'vp-link',
	templateUrl: './link.html',
})
export class LinkComponent {
	@Input() className = '';
	@Input({ required: true }) link: Link = {
		url: '',
	};
	@Input() type: 'link' | 'button' | 'link-unstyled' = 'link';
	@Input() buttonClassName = 'btn btn-primary';

	@Output() linkClick = new EventEmitter<void>();

	constructor(private readonly router: Router) {}

	setHash(hash: string): void {
		// This is a workaround for the fact that the routerLink directive does not support hashes.
		this.router.navigateByUrl(this.router.url + hash);
		/* istanbul ignore next */
		setTimeout(() => {
			window.location.reload();
		});
	}
}
