<div
	[ngClass]="{ 'section-inner-loose': !isFullscreen }"
	class="mx-auto items-center"
	data-test-class="storytelling"
>
	<div
		[ngClass]="{
			'lg:flex-row-reverse': position === 'right',
			'lg:pt-24 xl:pt-32':
				extraImage?.src !== '' && !isFullscreen && position === 'right',
			'lg:pb-24 xl:pb-32':
				extraImage?.src !== '' && !isFullscreen && position === 'left',
			'lg:flex': !isFullscreen,
		}"
		class="relative"
	>
		@if (extraImage?.src !== '' && !isFullscreen) {
			<vp-image
				[image]="{
					src: extraImage.src,
					altText: extraImage.alt,
					width: 192,
					height: 192,
				}"
				[ngClass]="{
					'lg:bottom-0 lg:left-16': position === 'left',
					'lg:right-16 lg:top-0': position === 'right',
				}"
				[sources]="[
					{ min: sizes.xl, max: null, width: 256, height: 256 },
					{ min: sizes.lg, max: sizes.xl, width: 192, height: 192 },
				]"
				class="absolute hidden lg:block"
			/>
		}
		<vp-image
			[className]="
				'max-w-full object-cover' +
				(isFullscreen
					? ' lg:size-full lg:h-[40rem]'
					: ' lg:max-w-[60rem] xl:max-w-screen-lg')
			"
			[image]="{
				src: image.src,
				altText: image.alt,
				width: !isFullscreen ? 1024 : 2048,
				height: !isFullscreen ? 576 : 640,
				style: styleHeight,
			}"
			[sources]="[
				{ min: null, max: sizes.sm, width: 768, height: 768 },
				{ min: sizes.md, max: sizes.lg, width: 1024, height: 576 },
				{
					min: sizes.xl,
					max: null,
					width: !isFullscreen ? 1024 : 1440,
					height: !isFullscreen ? 576 : 640,
				},
				{
					min: sizes.lg,
					max: sizes.xl,
					width: !isFullscreen ? 704 : 1408,
					height: !isFullscreen ? 396 : 640,
				},
			]"
		/>
		@if (isFullscreen) {
			<div
				class="section-inner-loose lg:absolute lg:inset-[var(--vp-section-spacing-x)] lg:mx-auto"
			>
				<ng-container
					[ngTemplateOutlet]="contentRef"
					[ngTemplateOutletContext]="{
						position:
							position === 'left'
								? 'lg:right-0 lg:top-1/2 lg:transform lg:-translate-y-1/2'
								: 'lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2',
					}"
				/>
			</div>
		} @else {
			<ng-container
				[ngTemplateOutlet]="contentRef"
				[ngTemplateOutletContext]="{
					position:
						position === 'left'
							? 'lg:bottom-auto lg:right-0 lg:top-16'
							: 'lg:bottom-16 lg:left-0 lg:top-auto',
				}"
			/>
		}
	</div>
</div>

<ng-template #contentRef let-position="position">
	<div
		#cardContent
		[ngClass]="position"
		class="card card-lg relative z-10 mx-4 -mt-16 block lg:card-xl sm:mx-8 lg:absolute lg:mx-0 lg:mt-0 lg:w-[32rem]"
	>
		<ng-content />
	</div>
</ng-template>
