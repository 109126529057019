import { AsyncPipe, CommonModule, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	Inject,
	Input,
	input,
	OnInit,
	output,
	PLATFORM_ID,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import {
	BehaviorSubject,
	debounceTime,
	filter,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';

import {
	AvailabilityDatesInterface,
	AvailabilityRequestInterface,
} from '@valk-nx/availability-dates-store/availability-dates.interface';
import { AvailabilityDatesService } from '@valk-nx/availability-dates-store/availability-dates.service';
import {
	HotelInterface,
	OccupancyRestrictionsInterface,
} from '@valk-nx/core/lib/interfaces/hotels.interface';
import { OccupancyInterface } from '@valk-nx/helpers/lib/interfaces/occupancy.interface';
import { toDate } from '@valk-nx/helpers/lib/transformers/toDate';
import { RouteHelper } from '@valk-nx/router-store/router.helper';

import { AvailabilityWidgetInputDateRangeComponent } from './availability-input-date-range/availability-input-date-range';
import { AvailabilityWidgetInputHotelsComponent } from './availability-input-hotels/availability-input-hotels';
import { AvailabilityWidgetInputOccupancyComponent } from './availability-input-occupancy/availability-input-occupancy';

export interface WidgetData {
	numRooms: number;
	numNights: number;
	selectedArrivalDate: string;
	selectedDepartureDate: string;
	price: number;
	currency: string;
	rooms: OccupancyInterface[];
}

export interface WidgetDataActionInterface {
	dayTypeBooking: string;
	dayTypeVisit: string;
	dealGUID?: string;
	hotelGUID: string;
	leadtime: number;
	numRooms: number;
	numNights: number;
	numGuests: number;
	packageGUID?: string;
	roomGUID?: string;
	rooms: {
		item_name: string;
		numAdults: number;
		numChildren: number;
		numBabies: number;
	}[];
	selectedArrivalDate: string;
	selectedDepartureDate: string;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget`,
	templateUrl: './availability-widget.component.html',
	standalone: true,
	imports: [
		AvailabilityWidgetInputDateRangeComponent,
		AvailabilityWidgetInputOccupancyComponent,
		AvailabilityWidgetInputHotelsComponent,
		AsyncPipe,
		CommonModule,
		TranslateModule,
	],
})
export class AvailabilityWidgetComponent implements OnInit {
	@Input() roomGUID: string | undefined;
	@Input() dealGUID: string | undefined;
	@Input() packageGUID: string | undefined;
	@Input() accommodationTypes: string[] | undefined;
	@Input() hotelGUID: string;
	@Input() defaultAdults = 2;
	@Input() occupancyRestrictions: OccupancyRestrictionsInterface = {
		adults: {
			min: 1,
			max: 3,
		},
		children: {
			min: 0,
			max: 1,
		},
		infants: {
			min: 0,
			max: 1,
		},
		maxPersons: 2,
		maxOccupancies: 5,
	};
	@Input() maxDate = dayjs().add(1, 'year').toDate();
	@Input() minDate = dayjs().subtract(1, 'day').toDate();
	@Input({ required: true }) bookingtoolUrl: string;
	@Input({ transform: toDate }) initialArrivalDate: Date;
	@Input() initialOccupancy: OccupancyInterface[];
	@Input() hotels: Partial<HotelInterface>[] = [];

	showHotels = input(false);
	showOccupancy = input(true);
	showDates = input(true);
	actionClick = output<{
		event: string;
		eventType: string;
		data: WidgetDataActionInterface;
	}>();

	occupancy: OccupancyInterface[];

	updateAvailability$ = new BehaviorSubject<AvailabilityRequestInterface>(
		undefined,
	);
	isLoading = false;
	jumpToFirstAvailableDate = true;

	availability$: Observable<AvailabilityDatesInterface>;
	firstDateInCalendar: string = dayjs().format('YYYY-MM-DD');
	isOccupancyOpen = false;
	isHotelsOpen = false;
	isDatePickerOpen = false;
	selectedHotel: Partial<HotelInterface>;

	arrivalDate: Date;
	departureDate: Date;

	hotelsInputBorderClasses = computed<string>(() => {
		if (this.showOccupancy() || this.showDates()) {
			return ` md:rounded-r-none`;
		}
		return '';
	});

	occupancyInputBorderClasses = computed<string>(() => {
		let classNames = '';
		if (this.showHotels()) {
			classNames = `${classNames} md:rounded-l-none md:-ml-px`;
		}
		if (this.showDates()) {
			classNames = `${classNames} md:rounded-r-none`;
		}
		return classNames;
	});

	datesInputBorderClasses = computed<string>(() => {
		if (this.showHotels() || this.showOccupancy()) {
			return ` md:rounded-l-none md:-ml-px`;
		}
		return '';
	});

	constructor(
		private readonly availability: AvailabilityDatesService,
		@Inject(PLATFORM_ID) readonly platformId: string,
	) {
		this.availability$ = this.updateAvailability$.pipe(
			debounceTime(300),
			filter((request) => !!request && !!request.hotelGUID),
			switchMap((request) =>
				this.availability.getAvailabilityDates(
					request.hotelGUID,
					request.endDate,
					request.startDate,
					request.occupancies,
					request.accommodationTypes,
					request.dealGUID,
					request.packageGUID,
					request.roomGUID,
				),
			),
			tap(() => (this.isLoading = false)),
			shareReplay(1),
		);
	}

	ngOnInit() {
		if (this.initialArrivalDate) {
			this.firstDateInCalendar = dayjs(this.initialArrivalDate)
				.startOf('month')
				.format('YYYY-MM-DD');
		}
		if (!this.showOccupancy()) {
			this.occupancy = [
				{ adults: this.defaultAdults, children: 0, infants: 0 },
			];
		}
		this.getAvailability();
	}

	onChooseDatesEmit(event: Event) {
		this.isOccupancyOpen = false;
		this.isDatePickerOpen = true;

		this.getAvailability();

		event.stopPropagation();
	}

	onDatePickerOpened(isOpen: boolean) {
		this.isDatePickerOpen = isOpen;
	}

	onOccupancyOpenEmit(isOpen: boolean) {
		this.isOccupancyOpen = isOpen;
	}

	onOccupancyEmit(occupancy: OccupancyInterface[]) {
		this.occupancy = occupancy;
		this.getAvailability();
	}

	onHotelSelect(hotel: Partial<HotelInterface>) {
		this.selectedHotel = hotel;
		this.getAvailability();
	}

	recalculateAvailabilityForToday() {
		this.firstDateInCalendar = dayjs()
			.startOf('month')
			.format('YYYY-MM-DD');
		this.getAvailability();
	}

	onMonthChange(newFirstDate: string) {
		this.firstDateInCalendar = newFirstDate;

		this.jumpToFirstAvailableDate = false;
		this.getAvailability();
	}

	getAvailability() {
		if (isPlatformBrowser(this.platformId)) {
			const request: AvailabilityRequestInterface = {
				accommodationTypes: this.accommodationTypes,
				hotelGUID: this.hotelGUID
					? this.hotelGUID
					: this.selectedHotel?.guid,
				startDate: this.firstDateInCalendar,
				endDate: dayjs(this.firstDateInCalendar)
					.add(2, 'month')
					.format('YYYY-MM-DD'),
				occupancies: this.occupancy,
				dealGUID: this.dealGUID,
				packageGUID: this.packageGUID,
				roomGUID: this.roomGUID,
			};

			this.isLoading = true;
			this.updateAvailability$.next(request);
		}
	}

	updateModel(event) {
		this.arrivalDate = event.arrivalDate;
		this.departureDate = event.departureDate;
	}

	goToBookingtool() {
		if (this.bookingtoolUrl) {
			const arrivalString = this.arrivalDate
				? `&startDate=${dayjs(this.arrivalDate).format('YYYY-MM-DD')}`
				: '';
			const departureString = this.departureDate
				? `&endDate=${dayjs(this.departureDate).format('YYYY-MM-DD')}`
				: '';
			const occupancyString = this.occupancy
				? `&occupancy=${JSON.stringify(this.occupancy)}`
				: '';
			const roomString = this.roomGUID
				? `&roomGUID=${this.roomGUID}`
				: '';
			const dealString = this.dealGUID
				? `&dealGUID=${this.dealGUID}`
				: '';
			const packageString = this.packageGUID
				? `&packageGUID=${this.packageGUID}`
				: '';
			const parameters = `${arrivalString}${departureString}${occupancyString}${roomString}${dealString}${packageString}`;

			const hotelurl = this.selectedHotel
				? `/${this.selectedHotel.slug}/configuration`
				: '';

			this.setEmittedData();

			RouteHelper.redirectToExternalUrl(
				`${this.bookingtoolUrl}${hotelurl}?${parameters}`,
			);
		}
	}

	setEmittedData() {
		const eventType = () => {
			if (this.packageGUID) {
				return 'packages';
			} else if (this.roomGUID) {
				return 'rooms';
			} else if (this.dealGUID) {
				return 'deals';
			} else {
				return 'default';
			}
		};
		const rooms = this.occupancy.map((occupancy, i) => ({
			item_name: `ROOM${i + 1}`,
			numAdults: occupancy.adults,
			numChildren: occupancy.children,
			numBabies: occupancy.infants,
		}));
		const dayTypeBooking =
			dayjs().day() === 0 || dayjs().day() === 6 ? 'weekend' : 'weekday';
		const dayTypeVisit =
			dayjs(this.arrivalDate).day() === 5 ||
			dayjs(this.arrivalDate).day() === 6
				? 'weekend'
				: 'weekday';

		this.actionClick.emit({
			event: 'search_availability',
			eventType: eventType(),
			data: {
				dayTypeBooking,
				dayTypeVisit,
				dealGUID: this.dealGUID,
				hotelGUID: this.hotelGUID,
				leadtime: dayjs(this.arrivalDate).diff(
					dayjs().format('YYYY-MM-DD'),
					'day',
				),
				numGuests: this.occupancy.reduce((acc, curr) => {
					return acc + curr.adults + curr.children + curr.infants;
				}, 0),
				numRooms: this.occupancy.length,
				numNights: dayjs(this.departureDate).diff(
					dayjs(this.arrivalDate),
					'days',
				),
				packageGUID: this.packageGUID,
				roomGUID: this.roomGUID,
				rooms,
				selectedArrivalDate: dayjs(this.arrivalDate).format(
					'YYYY-MM-DD',
				),
				selectedDepartureDate: dayjs(this.departureDate).format(
					'YYYY-MM-DD',
				),
			},
		});
	}
}
