import plugin from 'tailwindcss/plugin';

const card = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const base = `${theme('spacing')['4']}`;

		const variants = {
			xs: `var(--card-padding-xs, ${theme('spacing.2')})`,
			sm: `var(--card-padding-sm, ${theme('spacing.3')})`,
			md: `var(--card-padding-md, ${base})`,
			lg: `var(--card-padding-lg, ${theme('spacing.6')})`,
			xl: `var(--card-padding-xl, ${theme('spacing.8')})`,
		};

		addComponents({
			[`.${e('card')}`]: {
				paddingBottom: base,
				paddingLeft: base,
				paddingRight: base,
				paddingTop: base,
				backgroundColor: `var(--card-background-color, ${theme(
					'backgroundColor.white',
				)})`,
				borderColor: theme('borderColor.ui.100'),
				borderRadius: `var(--card-border-radius, ${
					theme('borderRadius')['DEFAULT']
				})`,
				borderWidth: theme('borderWidth')['DEFAULT'],
				...theme('elevations')[1],
			},
		});

		addComponents({
			[`.${e('card-form')}`]: {
				[`@media (min-width: ${theme('screens.md')})`]: {
					paddingBottom: theme('spacing.6'),
					paddingLeft: theme('spacing.16'),
					paddingRight: theme('spacing.16'),
					paddingTop: theme('spacing.6'),
				},
				[`@media (min-width: ${theme('screens.lg')})`]: {
					paddingLeft: theme('spacing.20'),
					paddingRight: theme('spacing.20'),
				},
				[`@media (min-width: ${theme('screens.2xl')})`]: {
					paddingLeft: theme('spacing.28'),
					paddingRight: theme('spacing.28'),
				},
			},
		});

		Object.keys(variants).forEach((key) => {
			addComponents({
				[`.${e('card-' + key)}`]: {
					paddingBottom: variants[key],
					paddingLeft: variants[key],
					paddingRight: variants[key],
					paddingTop: variants[key],
				},
			});

			addComponents({
				[`.${e('card-inner-' + key)}`]: {
					paddingBottom: variants[key],
					paddingLeft: variants[key],
					paddingRight: variants[key],
					paddingTop: variants[key],
					gap: theme('spacing.3'),
				},
			});
		});

		addComponents({
			[`.${e('card-no-padding')}`]: {
				padding: 0,
			},
		});

		addComponents({
			[`.${e('card-image-border-radius-t')}`]: {
				'border-top-left-radius': `var(--card-image-border-radius, ${theme('borderRadius.sm')})`,
				'border-top-right-radius': `var(--card-image-border-radius, ${theme('borderRadius.sm')})`,
				'border-bottom-left-radius': 0,
				'border-bottom-right-radius': 0,
				overflow: 'hidden',
			},
		});

		addComponents({
			[`.${e('card-image-border-radius-l')}`]: {
				'border-top-left-radius': `var(--card-image-border-radius, ${theme('borderRadius.sm')})`,
				'border-bottom-left-radius': `var(--card-image-border-radius, ${theme('borderRadius.sm')})`,
				'border-top-right-radius': 0,
				'border-bottom-right-radius': 0,
				overflow: 'hidden',
			},
		});
	};
});

export default card;
