import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { HOTEL_SLUG } from '@valk-nx/core/lib/core';
import { HALResponse } from '@valk-nx/core/lib/interfaces/hal.interface';
import { ContentHotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';

import {
	MeetingRoomResponseData,
	PackageData,
	RoomData,
} from './content.interface';

export const CONTENT_SERVICE_URL = new InjectionToken<string>(
	'content-service-url',
);
export const CONTENT_API_COOKIES = new InjectionToken<string[]>(
	'content-api-cookies',
);

@Injectable({ providedIn: 'root' })
export class ContentService {
	constructor(
		private readonly http: HttpClient,
		@Optional()
		@Inject(CONTENT_SERVICE_URL)
		private readonly serviceUrl: string,
		@Inject(CONTENT_API_COOKIES)
		private readonly apiCookies: { withCredentials: boolean },
		@Inject(HOTEL_SLUG)
		private readonly hotelSlug: string,
	) {}

	getRooms(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<RoomData[]>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/rooms?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getRoom(roomSlug: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<RoomData>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/rooms/${roomSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getPackage(packageSlug: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<PackageData>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/package-batches/${packageSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getPackages(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<PackageData[]>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/package-batches?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getMeetingRoom(meetingRoomSlug: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<MeetingRoomResponseData>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/meeting-rooms/${meetingRoomSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getMeetingRooms(language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<MeetingRoomResponseData[]>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}/meeting-rooms?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getHotelInfo(
		language: string,
	): Observable<HttpResponse<HALResponse<ContentHotelInterface>>> {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});

		return this.http.get<HALResponse<ContentHotelInterface>>(
			`${this.serviceUrl}hotels/${this.hotelSlug}?language=${language}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}

	getLegal(key: string, language: string) {
		const headers = new HttpHeaders({
			'Accept-Language': language,
		});
		const hotelSlug = this.hotelSlug ? `&hotel-slug=${this.hotelSlug}` : '';

		return this.http.get<HALResponse<string>>(
			`${this.serviceUrl}legal/${key}?language=${language}${hotelSlug}`,
			{
				...this.apiCookies,
				headers,
				observe: 'response',
			},
		);
	}
}
