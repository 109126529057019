<div class="relative flex h-[34rem] md:h-[40rem]">
	@if (items.length > 1) {
		<div class="absolute left-0 top-1/2 z-10 size-11 translate-y-[-50%]">
			<button
				(click)="slidePrev()"
				aria-label="Previous slide"
				class="elevation-1 flex h-full w-inherit items-center justify-center rounded-r-full bg-[#000]/50 outline-2 outline-offset-2 outline-white focus:outline"
				data-test-id="carousel__prev-button"
				role="button"
				type="button"
			>
				<svg-icon
					class="h-auto w-4 text-white"
					src="assets/icons/icon-chevron-left.svg"
				/>
			</button>
		</div>
	}
	@if (items.length > 1) {
		<div class="absolute right-0 top-1/2 z-10 size-11 translate-y-[-50%]">
			<button
				(click)="slideNext()"
				aria-label="Next slide"
				class="elevation-1 flex h-full w-inherit items-center justify-center rounded-l-full bg-[#000]/50 outline-2 outline-offset-2 outline-white focus:outline"
				data-test-id="carousel__next-button"
				role="button"
				type="button"
			>
				<svg-icon
					class="inline-block h-auto w-4 text-white"
					src="assets/icons/icon-chevron-right.svg"
				/>
			</button>
		</div>
	}
	<swiper-container
		#swiper
		class="w-full grow-0 rounded sm:order-2 sm:mb-0"
		init="false"
	>
		@for (item of items; track item; let i = $index) {
			<swiper-slide
				[ngClass]="{ 'opacity-0': i !== 0 }"
				class="cursor-grab"
			>
				<article
					[attr.aria-labelledby]="'slide-heading-' + i"
					class="h-full"
				>
					@if (!imagesLoaded[i] && isSSRMode) {
						<svg-icon
							class="mx-auto block size-16 animate-spin text-white"
							data-test-id="loader"
							src="assets/icons/icon-spinner.svg"
						/>
					}
					@defer {
						<img
							[alt]="item.image.alt"
							[ngClass]="{
								invisible: !imagesLoaded[i] && isSSRMode,
								'bg-ui-25 object-none': !isCustomImages[i],
							}"
							[src]="
								item.image.src
									| imageQuery: { height: 640, width: 2048 }
							"
							[srcset]="
								(item.image.src
									| imageQuery: { height: 544, width: 480 }) +
								' 500w,' +
								(item.image.src
									| imageQuery: { height: 640, width: 960 }) +
								' 900w,' +
								(item.image.src
									| imageQuery
										: { height: 640, width: 1440 }) +
								' 1600w,' +
								(item.image.src
									| imageQuery
										: { height: 640, width: 2048 }) +
								' 2000w'
							"
							(error)="setImageFallback(i)"
							(load)="onImageLoad(i)"
							class="size-full object-cover"
							data-test-class="carousel__image"
							height="auto"
							width="auto"
						/>
					} @placeholder {
						<img
							[alt]="item.image.alt"
							[src]="
								item.image.src
									| imageQuery: { height: 640, width: 2048 }
							"
							class="size-full object-cover"
							height="auto"
							width="auto"
						/>
					}
					<div
						class="absolute left-0 top-0 h-[17rem] w-full bg-gradient-to-b from-[#0000008C] to-transparent md:h-[20rem]"
					></div>
					@if (item.heading) {
						<div
							class="absolute bottom-0 left-0 h-[17rem] w-full bg-gradient-to-b from-transparent to-[#0000008C] md:h-[20rem]"
						></div>
					}
					<div class="px-3 md:px-8">
						<div class="section-inner">
							<div
								class="absolute bottom-16 z-10 flex flex-col text-white md:bottom-18"
							>
								@if (item.sticker?.src) {
									<vp-image
										[image]="{
											src: item.sticker!.src,
											altText: item.sticker?.altText,
											width: 80,
											height: 80,
										}"
										[sources]="[
											{
												min: null,
												max: sizes.md,
												width: 64,
												height: 64,
											},
											{
												min: sizes.md,
												max: null,
												width: 80,
												height: 80,
											},
										]"
										class="mb-4 size-16 md:mb-8 md:size-20"
									/>
								}
								<span
									[id]="'slide-heading-' + i"
									class="text-border mb-0 font-display text-3xl font-semibold text-border-dark-tertiary md:text-4xl md:leading-tight"
								>
									{{ item.heading }}
									@if (item.subHeading) {
										<span class="block font-normal">
											{{ item.subHeading }}
										</span>
									}
								</span>
								@if (item.link?.url) {
									<vp-link
										[link]="item.link"
										className="text-semibold mt-4 flex text-base text-white no-underline outline-2 outline-offset-2 outline-white visited:text-white hover:text-white focus:rounded-md focus:outline md:text-lg"
									>
										{{ item.link.content }}
										<svg-icon
											class="ml-1 inline-block size-4 self-center fill-white"
											src="assets/icons/icon-chevron-right.svg"
										/>
									</vp-link>
								}
							</div>
						</div>
					</div>
				</article>
			</swiper-slide>
		}
	</swiper-container>
</div>
