import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	Component,
	Inject,
	Input,
	OnChanges,
	PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';

import { HeaderComponent as VpHeaderComponent } from '@valk-nx/compositions/ui-header/src/lib/header.component';
import {
	HeaderMenuItem,
	MetaMenu,
	TranslatedSlug,
} from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	Link,
	MenuItem,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-header',
	templateUrl: './header.html',
	standalone: true,
	imports: [CommonModule, VpHeaderComponent, StoryblokDirective],
})
export class HeaderComponent
	extends StoryblokRootDirective
	implements OnChanges
{
	@Input() title!: string;
	@Input() set menuItems(menuItems: MenuItem[]) {
		this.headerMenu = this.constructMenuItems(menuItems);
	}
	@Input() accountLink!: Link;
	@Input() contactLink!: Link;
	@Input() callToActionTitle = '';
	@Input() callToActionLink!: Link;
	@Input() translatedSlugs!: TranslatedSlug[];
	@Input() menu!: HeaderMenuItem[];

	headerMenu!: HeaderMenuItem[];
	metaMenu!: MetaMenu;

	showSearch = false;

	constructor(
		public router: Router,
		@Inject(PLATFORM_ID) private readonly platformId: string,
	) {
		super();
	}

	ngOnChanges() {
		this.metaMenu = this.constructMetaMenu();
	}

	constructMenuItems(menuItems: MenuItem[]): HeaderMenuItem[] {
		return menuItems.map((item: MenuItem) => {
			return {
				title: item.title,
				url: item.link.cached_url,
				target: item.link.target || '_self',
			};
		});
	}

	constructMetaMenu(): MetaMenu {
		const slugs = this.translatedSlugs?.map((slug) => ({
			...slug,
			path: slug.path.endsWith('home')
				? slug.path.replace(/(\/?)home$/, '')
				: slug.path,
		}));
		const selectedSlug = slugs?.find((slug) => slug.selected);

		return {
			cta: {
				title: this.callToActionTitle,
				url: this.callToActionLink?.cached_url,
				target: this.callToActionLink?.target,
			},
			accountLink: {
				url: StoryblokHelper.getUrlFromStoryblokLink(
					this.accountLink,
				) as string,
				target: this.accountLink?.target,
			},
			contactLink: {
				url: StoryblokHelper.getUrlFromStoryblokLink(
					this.contactLink,
				) as string,
				target: this.contactLink?.target,
			},
			showSearch: this.showSearch,
			languages: {
				selectedLanguageOption: selectedSlug
					? selectedSlug
					: slugs?.[0],
				options: slugs?.map((slug) => {
					return {
						value: slug.path,
						label: slug.lang.toUpperCase(),
						selectLabel: slug.name,
					};
				}),
			},
		};
	}

	onLanguageSwitch(url: string): Promise<boolean> {
		const urlSegments = this.getCurrentUrl().split('?');
		if (urlSegments.length > 1) {
			return this.router.navigateByUrl(`${url}?${urlSegments[1]}`);
		}
		return this.router.navigateByUrl(url);
	}

	getCurrentUrl(): string {
		if (isPlatformBrowser(this.platformId)) {
			return window.location.href;
		}
		return '';
	}
}
