<section
	#persuadeRef
	*ngrxLet="isMobile$ as isMobile"
	[attr.aria-label]="title"
	class="card card-xs card-no-padding relative flex size-full flex-col"
	data-test-class="persuade-card"
	role="complementary"
>
	<div
		[ngClass]="{ 'md:flex-row': !useVerticalCard }"
		class="flex h-full flex-col"
	>
		<div
			[ngClass]="{ 'md:grow md:flex-row': !useVerticalCard }"
			class="card-inner-xs flex flex-col"
		>
			@if (images?.length > 0) {
				<div
					[ngClass]="{ 'md:max-w-64 md:basis-1/3': !useVerticalCard }"
				>
					@switch (imageVariant) {
						@case ('single') {
							<ng-container
								[ngTemplateOutlet]="singleImage"
								[ngTemplateOutletContext]="{
									images: images,
								}"
							/>
						}
						@case ('mini-carousel') {
							<vp-mini-carousel
								[className]="
									useVerticalCard
										? 'card-image-border-radius-t'
										: 'card-image-border-radius-t md:card-image-border-radius-l'
								"
								[dynamicHeight]="
									!isMobile && !useVerticalCard ? height : 0
								"
								[items]="carouselItems"
								aspectRatio="3:2"
								class="h-auto min-w-full"
							/>
						}
						@case ('grid') {
							<vp-image-grid
								[className]="
									useVerticalCard
										? 'card-image-border-radius-t'
										: 'card-image-border-radius-t md:card-image-border-radius-l'
								"
								[images]="images || []"
								[size]="!useVerticalCard ? 'small' : 'base'"
							/>
						}
					}
				</div>
			}
			<div
				[ngClass]="{
					'md:basis-2/3': !useVerticalCard && images?.length > 0,
				}"
				class="flex flex-col items-start gap-5 p-3 pb-4"
			>
				<div class="w-full">
					<header>
						<div
							[heading]="headingType"
							class="heading-group"
							data-test-id="persuade-card-title"
							vc-heading
						>
							<a [routerLink]="cardUrl">{{ title }}</a>
						</div>
						@if (overline) {
							<p
								class="heading-group-overline"
								role="doc-subtitle"
							>
								{{ overline }}
							</p>
						}
					</header>
					@if (facilities || roomSurface) {
						<vp-room-facilities
							[babyBeds]="0"
							[facilities]="facilities"
							[maxNumberOfGuests]="0"
							[roomIndex]="0"
							[roomSurface]="roomSurface"
							[showMoreInformationAccordion]="false"
						/>
					}
				</div>
				<div class="w-full">
					@if (content) {
						<markdown
							[data]="content"
							class="list-checkmarks markdown"
							data-test-id="persuade-card-description"
						/>
					} @else {
						<div data-test-id="persuade-card-content">
							<ng-content select="[content]" />
						</div>
					}
					@if (showReadMore) {
						<a
							[routerLink]="cardUrl"
							class="text-link"
							data-test-id="persuade-card-link"
						>
							{{ 'global.show-more' | translate }}
						</a>
					}
					@if (tags?.length > 0) {
						<div class="mt-6 inline-flex w-full flex-wrap gap-1.5">
							@for (tag of tags; track tag) {
								<vp-tag
									[color]="'green'"
									[isEmphasized]="false"
								>
									{{ tag }}
								</vp-tag>
							}
						</div>
					}
				</div>
			</div>
		</div>
		<div
			[ngClass]="{
				'md:w-fit': !useVerticalCard,
				'mt-auto': useVerticalCard,
			}"
		>
			<div
				[ngClass]="{
					'h-full !pt-0 md:flex-col md:!pt-5 md:pl-0':
						!useVerticalCard,
					'pt-0': useVerticalCard,
					'flex-row-reverse': reversedCtaColumn && useVerticalCard,
					'md:flex-col-reverse':
						reversedCtaColumn && !useVerticalCard,
				}"
				class="flex min-w-max flex-row justify-between gap-4 p-5"
			>
				@if (price) {
					<vp-pricing
						*ngrxLet="isTablet$ as isTablet"
						[alignment]="
							(isMobile || isTablet || useVerticalCard) &&
							!reversedCtaColumn
								? 'self-start'
								: 'self-end'
						"
						[conditions]="pricingCondition | translate"
						[currency]="currency"
						[indication]="true"
						[ngClass]="{
							'md:ml-auto': !useVerticalCard,
						}"
						[price]="price"
						[size]="isMobile || isTablet ? 'sm' : 'base'"
						class="ml-0"
					/>
				} @else {
					<div data-test-id="persuade-card-footer">
						<ng-content select="[footerContent]" />
					</div>
				}
				<div class="self-end sm:block">
					@if (ctaUrl) {
						<a
							[href]="ctaUrl"
							[ngClass]="ctaType + ' ' + ctaVariant"
							class="btn btn-sm md:btn"
							data-test-id="persuade-card-cta"
							type="button"
						>
							{{ ctaText | translate }}
						</a>
					}
				</div>
			</div>
		</div>
	</div>
</section>

<ng-template #singleImage let-images="images">
	<vp-carousel-modal [images]="images" class="relative" className="h-full">
		<div class="relative h-full w-28 min-w-full md:w-44 lg:w-64">
			<vp-image
				[className]="
					'size-full object-cover ' +
					(useVerticalCard
						? 'card-image-border-radius-t'
						: 'card-image-border-radius-t md:card-image-border-radius-l')
				"
				[image]="{
					src: images[0].src,
					altText: images[0].alt,
					width: 652,
					height: 460,
				}"
				className="size-full object-cover"
			/>
			<div
				class="absolute bottom-0 right-0 rounded-br-sm rounded-tl-2xl bg-[#000]/50 pb-2 pl-3 pr-2 pt-3"
			>
				<svg-icon
					class="block size-4 text-white"
					src="assets/icons/icon-looking-glass.svg"
					svgAriaLabel="Open"
				/>
			</div>
		</div>
	</vp-carousel-modal>
</ng-template>
