<div
	class="card flex flex-col gap-4 md:flex-row"
	data-test-id="availability-widget"
>
	<div class="flex size-full flex-col children:flex-1 md:flex-row">
		@if (showHotels() && hotels.length > 0) {
			<vp-availability-widget-input-hotels
				[className]="
					'focus:relative focus:z-10 max-md:-mb-px max-md:rounded-b-none' +
					hotelsInputBorderClasses()
				"
				[hotels]="hotels"
				[(isOpen)]="isHotelsOpen"
				(hotelSelected)="onHotelSelect($event)"
				data-test-id="hotels-selector"
			/>
		}
		@if (showOccupancy()) {
			<vp-availability-widget-input-occupancy
				[className]="
					'focus:relative focus:z-10 max-md:-mb-px max-md:rounded-b-none' +
					occupancyInputBorderClasses()
				"
				[defaultAdults]="defaultAdults"
				[initialOccupancy]="initialOccupancy"
				[isOpen]="isOccupancyOpen"
				[maxRooms]="occupancyRestrictions.maxOccupancies"
				[occupancyRestrictions]="occupancyRestrictions"
				(emitChooseDates)="onChooseDatesEmit($event)"
				(emitIsPopoverOpen)="onOccupancyOpenEmit($event)"
				(occupancyChanged)="onOccupancyEmit($event)"
				data-test-id="occupancy-selector"
			/>
		}
		@if (showDates()) {
			<vp-availability-widget-input-date-range
				[availability]="availability$ | async"
				[className]="
					'md:focus:border-l max-md:rounded-t-none' +
					datesInputBorderClasses()
				"
				[config]="{
					mode: 'range',
					showMonths: 2,
					minDate: minDate,
					maxDate: maxDate,
				}"
				[initialArrivalDate]="initialArrivalDate"
				[isLoading]="isLoading"
				[isOpen]="isDatePickerOpen"
				[jumpToFirstAvailableDate]="jumpToFirstAvailableDate"
				(datePickerModelChanged)="updateModel($event)"
				(emitIsPopoverOpen)="onDatePickerOpened($event)"
				(emitMonthChange)="onMonthChange($event)"
				(emitShowAvailabilityButton)="goToBookingtool()"
				(initialDatesNotAvailable)="recalculateAvailabilityForToday()"
				data-test-id="dates-selector"
			/>
		}
	</div>
	<button
		[disabled]="showHotels() && !selectedHotel"
		(click)="goToBookingtool()"
		class="btn btn-primary w-full md:h-auto md:w-fit"
		data-test-id="cta-button"
	>
		{{ 'global.show-availability' | translate }}
	</button>
</div>
