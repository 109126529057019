import plugin from 'tailwindcss/plugin';

const section = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		addComponents({
			[`.${e('section-inner')}`]: {
				'--vp-section-inner-spacing-y':
					'var(--section-inner-spacing-y, 0rem)',
				'--vp-section-inner-spacing-x':
					'var(--section-inner-spacing-x, 0rem)',

				maxWidth: `var(--section-inner-max-width, ${theme('screens.xl')})`,
				marginLeft: 'auto',
				marginRight: 'auto',
				padding:
					'var(--vp-section-inner-spacing-y) var(--vp-section-inner-spacing-x)',

				'@screen md': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-md, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-md, 0rem)',
				},

				'@screen lg': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-lg, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-lg, 0rem)',
				},

				'@screen xl': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-xl, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-xl, 4rem)',
				},
			},

			[`.${e('section-inner-tight')}`]: {
				'--vp-section-inner-spacing-y':
					'var(--section-inner-spacing-y-tight, 0rem)',
				'--vp-section-inner-spacing-x':
					'var(--section-inner-spacing-x-tight, 0rem)',

				maxWidth: `var(--section-inner-max-width, ${theme('screens.xl')})`,
				marginLeft: 'auto',
				marginRight: 'auto',
				padding:
					'var(--vp-section-inner-spacing-y) var(--vp-section-inner-spacing-x)',

				'@screen md': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-md-tight, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-md-tight, 0rem)',
				},

				'@screen lg': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-lg-tight, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-lg-tight, 0rem)',
				},

				'@screen xl': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-xl-tight, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-xl-tight, 6rem)',
				},
			},

			[`.${e('section-inner-loose')}`]: {
				'--vp-section-inner-spacing-y':
					'var(--section-inner-spacing-y-loose, 0rem)',
				'--vp-section-inner-spacing-x':
					'var(--section-inner-spacing-x-loose, 0rem)',

				maxWidth: `var(--section-inner-max-width, ${theme('screens.xl')})`,
				marginLeft: 'auto',
				marginRight: 'auto',
				padding:
					'var(--vp-section-inner-spacing-y) var(--vp-section-inner-spacing-x)',

				'@screen md': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-md-loose, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-md-loose, 0rem)',
				},

				'@screen lg': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-lg-loose, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-lg-loose, 0rem)',
				},

				'@screen xl': {
					'--vp-section-inner-spacing-y':
						'var(--section-inner-spacing-y-xl-loose, 0rem)',
					'--vp-section-inner-spacing-x':
						'var(--section-inner-spacing-x-xl-loose, 0rem)',
				},
			},

			[`.${e('section-spacing')}`]: {
				'--vp-section-spacing-y': 'var(--section-spacing-y, 2rem)',
				'--vp-section-spacing-x': 'var(--section-spacing-x, 0.75rem)',

				padding:
					'var(--vp-section-spacing-y) var(--vp-section-spacing-x)',

				'@screen md': {
					'--vp-section-spacing-y':
						'var(--section-spacing-y-md, 3rem)',
					'--vp-section-spacing-x':
						'var(--section-spacing-x-md, 2rem)',
				},

				'@screen lg': {
					'--vp-section-spacing-y':
						'var(--section-spacing-y-lg, 4rem)',
					'--vp-section-spacing-x':
						'var(--section-spacing-x-lg, 2rem)',
				},

				'@screen 2xl': {
					'--vp-section-spacing-y':
						'var(--section-spacing-y-2xl, 4rem)',
					'--vp-section-spacing-x':
						'var(--section-spacing-x-2xl, 2.5rem)',
				},
			},

			[`.${e('section-gap-tight')}`]: {
				'--vp-section-gap-y': 'var(--section-gap-y-tight, 0.75rem)',
				'--vp-section-gap-x': 'var(--section-gap-x-tight, 0.75rem)',

				display: 'flex',
				flexDirection: 'column',
				gap: 'var(--vp-section-gap-y) var(--vp-section-gap-x)',

				'@screen md': {
					'--vp-section-gap-y':
						'var(--section-gap-y-tight-md, 1.25rem)',
					'--vp-section-gap-x':
						'var(--section-gap-x-tight-md, 1.25rem)',
				},

				'@screen 2xl': {
					'--vp-section-gap-y':
						'var(--section-gap-y-tight-2xl, 1.75rem)',
					'--vp-section-gap-x':
						'var(--section-gap-x-tight-2xl, 1.75rem)',
				},
			},

			[`.${e('section-gap')}`]: {
				'--vp-section-gap-y': 'var(--section-gap-y, 1rem)',
				'--vp-section-gap-x': 'var(--section-gap-x, 1rem)',

				display: 'flex',
				flexDirection: 'column',
				gap: 'var(--vp-section-gap-y) var(--vp-section-gap-x)',

				'@screen md': {
					'--vp-section-gap-y': 'var(--section-gap-y-md, 1.5rem)',
					'--vp-section-gap-x': 'var(--section-gap-x-md, 1.5rem)',
				},

				'@screen 2xl': {
					'--vp-section-gap-y': 'var(--section-gap-y-2xl, 2rem)',
					'--vp-section-gap-x': 'var(--section-gap-x-2xl, 2rem)',
				},
			},

			[`.${e('section-gap-loose')}`]: {
				'--vp-section-gap-y': 'var(--section-gap-y-loose, 1.25rem)',
				'--vp-section-gap-x': 'var(--section-gap-x-loose, 1.25rem)',

				display: 'flex',
				flexDirection: 'column',
				gap: 'var(--vp-section-gap-y) var(--vp-section-gap-x)',

				'@screen md': {
					'--vp-section-gap-y':
						'var(--section-gap-y-loose-md, 1.75rem)',
					'--vp-section-gap-x':
						'var(--section-gap-x-loose-md, 1.75rem)',
				},

				'@screen 2xl': {
					'--vp-section-gap-y':
						'var(--section-gap-y-loose-2xl, 2.25rem)',
					'--vp-section-gap-x':
						'var(--section-gap-x-loose-2xl, 2.25rem)',
				},
			},

			[`.${e('column-gap')}`]: {
				display: 'flex',
				gap: 'var(--vp-section-gap-y) var(--vp-section-gap-x)',

				'& > *': {
					display: 'flex',
					gap: 'var(--vp-section-gap-y) var(--vp-section-gap-x)',
				},
			},
		});
	};
});

export default section;
