<div
	[editableInStoryblok]="_editable"
	class="card card-xs card-no-padding relative flex h-full flex-col"
>
	<div
		[ngClass]="{ 'md:flex-row': isHorizontal }"
		class="flex h-full flex-col"
	>
		<div
			[ngClass]="{ 'md:grow md:flex-row': isHorizontal }"
			class="card-inner-xs flex flex-col"
		>
			@if (flatImages?.length === 1) {
				<div [ngClass]="{ 'md:max-w-64 md:basis-1/3': isHorizontal }">
					<sb-image
						[aspectRatio]="
							!isHorizontal || (isResponsiveVertical$ | async)
								? '16:9'
								: '1:1'
						"
						[bynder]="bynderImages[0].bynder"
						[className]="
							isHorizontal
								? 'card-image-border-radius-t md:card-image-border-radius-l'
								: 'card-image-border-radius-t'
						"
					/>
				</div>
			}
			@if (flatImages?.length > 1) {
				<div [ngClass]="{ 'md:max-w-64 md:basis-1/3': isHorizontal }">
					<vp-image-grid
						[className]="
							isHorizontal
								? 'card-image-border-radius-t md:card-image-border-radius-l'
								: 'card-image-border-radius-t'
						"
						[images]="flatImages || []"
						[size]="isHorizontal ? 'small' : 'base'"
					/>
				</div>
			}
			<div
				[ngClass]="{
					'md:basis-2/3': isHorizontal && flatImages?.length > 0,
				}"
				class="flex flex-col items-start gap-4 p-3 pb-4"
			>
				@if (components) {
					@for (
						inputs of [headingInputs, contentInputs];
						track $index
					) {
						@for (blok of inputs; track $index) {
							<ndc-dynamic
								[ndcDynamicComponent]="
									components[blok['component']]
								"
								[ndcDynamicInputs]="blok"
								class="hidden"
							/>
						}
					}
				}
			</div>
		</div>
		<div [ngClass]="{ 'md:w-fit': isHorizontal, 'mt-auto': !isHorizontal }">
			@for (blok of footerInputs; track $index) {
				@if (components) {
					<div
						[ngClass]="{
							'p-5 pt-0': blok.component === 'button',
							'pt-5': isHorizontal,
						}"
					>
						<ndc-dynamic
							[ndcDynamicComponent]="
								components[blok['component']]
							"
							[ndcDynamicInputs]="blok"
							class="hidden"
						/>
					</div>
				}
			}
		</div>
	</div>
</div>
