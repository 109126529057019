import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { map, Observable, switchMap, take } from 'rxjs';

import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import { ImageInterface } from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { RatingComponent } from '@valk-nx/components/ui-rating/src/lib/rating';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { ReadMoreHelper } from '@valk-nx/components/ui-read-more/src/lib/read-more.helper';
import { RoomFacilitiesComponent } from '@valk-nx/components/ui-room-facilities/src/lib/room-facilities.component';
import { SheetModule } from '@valk-nx/components/ui-sheet/src/lib/sheet.module';
import { TabMenuComponent } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu';
import { TabMenuItemDirective } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu.directive';
import { TagComponent } from '@valk-nx/components/ui-tag/src/lib/tag';
import {
	AvailabilityWidgetComponent,
	WidgetDataActionInterface,
} from '@valk-nx/compositions/ui-availability-widget/src/lib/availability-widget.component';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { HOTEL_SLUG, negativeHeader$ } from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { AccommodationTypeModule } from '@valk-nx/core/lib/pipes/accommodation-type/accommodation-type.module';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { RoomData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';
import { TagManagerFacade } from '@valk-nx/storyblok-store/src/lib/store/tag-manager/tag-manager.facade';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'sb-room-detail',
	templateUrl: './room-detail.component.html',
	standalone: true,
	imports: [
		AccommodationTypeModule,
		AvailabilityWidgetComponent,
		CarouselComponent,
		CommonModule,
		MarkdownModule,
		PersuadeCardComponent,
		ReadMoreComponent,
		RoomFacilitiesComponent,
		TranslateModule,
		MiniCarouselComponent,
		MarkdownModule,
		RatingComponent,
		RouterModule,
		SheetModule,
		TabMenuComponent,
		TabMenuItemDirective,
		TagComponent,
		TranslateModule,
	],
})
export class RoomDetailComponent
	extends StoryblokRootDirective
	implements OnInit, AfterViewInit
{
	@Input({ required: true }) canonical!: string;
	@ViewChild('contentRef', { read: ElementRef }) contentRef: ElementRef;

	roomSlug = '';
	isSmallTablet$: Observable<boolean>;
	roomData$: Observable<RoomData>;
	images: CarouselItem[] = [];
	hotelImages: ImageInterface[] = [];
	roomFacilities: string[] = [];
	disabledReadmore = true;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private readonly metadataService: MetadataService,
		private readonly viewportService: ViewPortService,
		private readonly tagManagerFacade: TagManagerFacade,
		readonly cd: ChangeDetectorRef,
		@Inject(PLATFORM_ID) readonly platformId: string,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
		this.isSmallTablet$ = this.viewportService.isSmallTablet$;
		this.roomData$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) => {
				this.roomSlug = slug.path.split('/').pop();
				return this.contentService.getRoom(
					this.roomSlug,
					slug.lang.toLowerCase(),
				);
			}),
			map((response) => {
				const roomData = response.body.data;
				this.metadataService.updateMetaDataBatch(roomData);

				this.images = roomData.images.map(
					(image: { src: string; alt: string }) => {
						return {
							image: {
								src: image.src,
								alt: image.alt,
							},
							heading: null,
							subHeading: null,
							link: null,
						};
					},
				);

				const { misc, generic, sleepingComfort, bathroom } =
					roomData.facilities;
				const optionalGeneric = generic || [];
				const facilities = [
					...sleepingComfort,
					...bathroom,
					...misc,
					...optionalGeneric,
				];
				this.roomFacilities = [...new Set(facilities)];

				if (this.images.length > 0) {
					negativeHeader$.next(true);
				}

				this.hotelImages = roomData.hotel?.images.map(
					(image: { src: string; alt: string }) => {
						return {
							src: image.src,
							altText: image.alt,
							width: 0,
							height: 0,
						};
					},
				);

				return roomData;
			}),
		);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.determineReadMore();
		});
	}

	ngOnInit() {
		negativeHeader$.next(false);
	}

	@HostListener('window:resize')
	@Debounce(300)
	determineReadMore() {
		this.viewportService.isSmallTablet$
			.pipe(take(1))
			.subscribe((isSmallTablet) => {
				if (isPlatformBrowser(this.platformId)) {
					this.disabledReadmore = ReadMoreHelper.disabledReadmore(
						this.contentRef.nativeElement.offsetHeight,
						isSmallTablet,
					);
					this.cd.detectChanges();
				}
			});
	}

	widgetCTA(eventData: {
		event: string;
		eventType: string;
		data: WidgetDataActionInterface;
	}) {
		this.tagManagerFacade.availabilityWidget({
			...eventData,
			data: {
				...eventData.data,
				hotelSlug: this.hotelSlug,
				roomSlug: this.roomSlug,
			},
		});
	}
}
