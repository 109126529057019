import {
	ChangeDetectionStrategy,
	Component,
	computed,
	input,
	model,
	OnInit,
	output,
	signal,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { HotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';

import { AvailabilityWidgetInputComponent } from '../availability-input/availability-input';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-availability-widget-input-hotels`,
	standalone: true,
	templateUrl: './availability-input-hotels.html',
	imports: [AvailabilityWidgetInputComponent, TranslateModule],
})
export class AvailabilityWidgetInputHotelsComponent implements OnInit {
	hotels = input.required<Partial<HotelInterface>[]>();
	className = input('');
	excludeFromClosingRegex = input<RegExp | string>(/ng-option/);

	isOpen = model<boolean>(false);

	hotelSelected = output<Partial<HotelInterface>>();

	selectedHotelName = signal<string>('');

	iconName = computed<string>(() => {
		if (this.hotels().length > 1) {
			return this.isOpen() ? 'icon-chevron-up' : 'icon-chevron-down';
		}
		return '';
	});

	ngOnInit() {
		if (this.hotels().length === 1) {
			this.onSelectHotel(this.hotels()[0]);
		}
	}

	popoverStateChanged(isOpen: boolean) {
		this.isOpen.set(isOpen);
	}

	onSelectHotel(hotel: Partial<HotelInterface>) {
		this.selectedHotelName.set(hotel.name);
		this.popoverStateChanged(false);
		this.hotelSelected.emit(hotel);
	}
}
