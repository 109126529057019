import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	input,
} from '@angular/core';

import { ISbRichtext, renderRichText } from '@storyblok/js';

import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'sb-richtext',
	templateUrl: './rich-text.html',
	standalone: true,
	imports: [ReadMoreComponent, NgClass, StoryblokDirective],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RichTextComponent extends StoryblokRootDirective {
	@Input() listStyle: 'list-checkmarks' | 'list-bullets' = 'list-bullets';
	@Input() isReadMore = false;
	@Input() maxWidth: 'max-w-screen-md' | 'section-inner' = 'max-w-screen-md';

	content = input<ISbRichtext | string>('');
	renderedRichText = computed<string | ISbRichtext>(() => {
		const content = this.content();
		if (typeof content !== 'string') {
			return renderRichText(content);
		} else {
			return content;
		}
	});
}
