<section
	[editableInStoryblok]="_editable"
	class="section-spacing"
	data-test-class="masonry-section"
>
	<div class="section-inner section-gap">
		<sb-columns [colOne]="titleWithFigures" component="columnOne" />
		<vp-masonry [images]="vpImages" />
	</div>
</section>
