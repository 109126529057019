import { Component, Input, OnChanges } from '@angular/core';

import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { Link } from '@valk-nx/components/ui-link/src/lib/link.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { Link as StoryblokLink } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-anchor',
	templateUrl: './anchor.html',
	standalone: true,
	imports: [LinkComponent, StoryblokDirective],
})
export class AnchorComponent
	extends StoryblokRootDirective
	implements OnChanges
{
	@Input({ required: true }) text!: string;
	@Input({ required: true }) link!: StoryblokLink;
	@Input() type: 'primary' | 'secondary' = 'primary';

	anchorLink: Link = {
		url: '',
		title: '',
		target: '',
	};
	className = '';

	ngOnChanges() {
		this.className =
			this.type !== 'primary' ? 'btn btn-secondary' : 'btn btn-primary';
		this.setAnchorLink();
	}

	setAnchorLink() {
		this.anchorLink = {
			url:
				this.link.linktype === 'email'
					? `mailto:${this.link.cached_url}`
					: this.link.cached_url,
			title: this.text,
			target: this.link.target,
		};
	}
}
