import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
	Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { UspBarComponent } from '@valk-nx/components/ui-usp-bar/src/lib/usp-bar.component';
import { USPInterface } from '@valk-nx/components/ui-usp-bar/src/lib/usp-bar.interface';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	Link,
	Usp,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-usp-wrapper',
	templateUrl: './usp-wrapper.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [StoryblokDirective, UspBarComponent],
})
export class UspWrapperComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) USP: Usp[] = [];
	usps: USPInterface[] = [];

	screenWidth: Signal<number | undefined>;

	constructor(private readonly viewportService: ViewPortService) {
		super();
		this.screenWidth = toSignal(this.viewportService.screenWidth$);
	}

	ngOnInit() {
		this.usps = this.USP.map((item) => {
			const link = item.link as Link;
			const cachedUrl =
				link?.linktype === 'email'
					? `mailto:${link?.cached_url}`
					: link?.cached_url;
			return {
				...item,
				link: cachedUrl,
				target: link?.target,
			};
		});
	}
}
