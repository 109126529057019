import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { DropdownComponent } from '@valk-nx/components/ui-dropdown/src/lib/dropdown';
import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { Link } from '@valk-nx/components/ui-link/src/lib/link.interface';
import { fallbackLanguage } from '@valk-nx/core/lib/core';
import { Debounce } from '@valk-nx/core/lib/decorators/debounce';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

import { HeaderMenuItem, MetaMenu } from '../header.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-header-mobile`,
	templateUrl: './header-mobile.html',
	standalone: true,
	imports: [
		AngularSvgIconModule,
		CommonModule,
		DropdownComponent,
		LinkComponent,
		RouterModule,
		TranslateModule,
	],
})
export class HeaderMobileComponent implements OnChanges, AfterViewInit {
	@Input({ required: true }) menuItems: HeaderMenuItem[];
	@Input({ required: true }) metaMenu: MetaMenu;
	@Input() title: string;

	@Output() languageSwitch = new EventEmitter<string>();

	@ViewChild('mobileHeaderEl')
	mobileHeaderElement: ElementRef<HTMLInputElement>;
	@ViewChild('mobileMenuEl') mobileMenuElement: ElementRef<HTMLInputElement>;
	@ViewChild('metaMenuEl') metaMenuElement: ElementRef<HTMLInputElement>;
	@ViewChild('languageMenuEl')
	languageMenuElement: ElementRef<HTMLInputElement>;

	menuAnimation: KeyframeAnimationOptions = {
		duration: 300,
		easing: 'ease-out',
		fill: 'both',
	};

	menuIsOpen: boolean;
	languageIsOpen: boolean;
	logoLink: Link;
	mobileHeaderDeltaY: number;
	mobileMenuDeltaY: number;
	metaMenuDeltaY: number;
	languageMenuDeltaY: number;
	topBannerHeight: number;

	language: string;

	mobileMenuAnimation: Animation;

	constructor(
		public translate: TranslateService,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly cd: ChangeDetectorRef,
	) {
		this.language =
			this.translate?.currentLang || this.translate?.defaultLang;
	}

	ngOnChanges() {
		this.logoLink = {
			url:
				this.translate.currentLang === fallbackLanguage
					? '/'
					: `/${this.translate.currentLang}`,
		};
	}

	ngAfterViewInit() {
		this.setupAnimationPositions();
	}

	@HostListener('window:resize')
	@Debounce()
	/* istanbul ignore next */
	setupAnimationPositions() {
		// Always close menu if it is open to prevent unwanted interactions
		// Without detectChanges, the menu will not properly close when you close devtools or switch between desktop and mobile views
		if (this.languageIsOpen) {
			this.toggleLanguageMenu();
			this.cd.detectChanges();
		}
		if (this.menuIsOpen) {
			this.toggleMenu();
			this.cd.detectChanges();
		}
		if (isPlatformBrowser(this.platformId) && window.innerWidth < 1024) {
			// Save the current state so we can reapply it after the animation
			const initialMenuIsOpen = this.menuIsOpen;
			const initialLanguageIsOpen = this.languageIsOpen;

			// Get the nativeElements of the elements we want to animate
			const mobileHeader = this.mobileHeaderElement.nativeElement;
			const mobileMenu = this.mobileMenuElement.nativeElement;
			const metaMenu = this.metaMenuElement.nativeElement;
			const languageMenu = this.languageMenuElement.nativeElement;

			// Get the start bounding boxes of the elements we want to animate
			const mobileHeaderBoundingStart =
				mobileHeader.getBoundingClientRect();
			const mobileMenuBoundingStart = mobileMenu.getBoundingClientRect();
			const metaMenuBoundingStart = metaMenu.getBoundingClientRect();
			const languageMenuBoundingStart =
				languageMenu.getBoundingClientRect();

			// Get the end bounding boxes of the elements we want to animate
			this.menuIsOpen = true;
			const mobileMenuBoundingEnd = mobileMenu.getBoundingClientRect();
			const metaMenuBoundingEnd = metaMenu.getBoundingClientRect();
			this.menuIsOpen = initialMenuIsOpen;
			this.languageIsOpen = true;
			const languageMenuBoundingEnd =
				languageMenu.getBoundingClientRect();
			this.languageIsOpen = initialLanguageIsOpen;

			// Check if a top banner exists and adjust the height of the mobile menu accordingly
			const topBanner = this.document.querySelector('#top-banner');
			this.topBannerHeight =
				topBanner?.getBoundingClientRect().height || 0;

			// Calculate the delta Y for the animations
			this.mobileHeaderDeltaY =
				mobileHeaderBoundingStart.bottom -
				mobileHeaderBoundingStart.top;
			this.mobileMenuDeltaY =
				mobileMenuBoundingStart.top - mobileMenuBoundingEnd.top;
			this.metaMenuDeltaY =
				metaMenuBoundingStart.top - metaMenuBoundingEnd.top;
			this.languageMenuDeltaY =
				languageMenuBoundingStart.top - languageMenuBoundingEnd.top;

			// Set the fixed height of the mobile menu so the animation looks better
			const menuHeight =
				'calc(100% - ' +
				(metaMenuBoundingStart.bottom -
					metaMenuBoundingStart.top +
					(mobileHeaderBoundingStart.bottom -
						mobileHeaderBoundingStart.top) +
					this.topBannerHeight) +
				'px)';

			mobileMenu.style.height = menuHeight;
		}
	}

	toggleMenu() {
		if (isPlatformBrowser(this.platformId)) {
			this.menuIsOpen = !this.menuIsOpen;
			GeneralHelper.bodyLock(this.menuIsOpen, window.scrollY);

			if (this.languageIsOpen && this.menuIsOpen) {
				this.toggleLanguageMenu();
			}

			if (!this.mobileMenuAnimation) {
				this.mobileMenuAnimation =
					this.mobileMenuElement.nativeElement.animate(
						[
							{
								transformOrigin: 'top left',
								transform: `translateY(${this.mobileMenuDeltaY + this.mobileHeaderDeltaY + this.topBannerHeight}px)`,
							},
						],
						this.menuAnimation,
					);

				this.mobileMenuAnimation.pause();
			}

			if (this.menuIsOpen) {
				this.mobileMenuAnimation.play();
			} else {
				this.mobileMenuAnimation.reverse();
				/* istanbul ignore next */
				this.mobileMenuAnimation.onfinish = () => {
					this.mobileMenuAnimation.cancel();
					this.mobileMenuAnimation = null; // Reset the animation state
				};
			}

			this.metaMenuElement.nativeElement.animate(
				[
					{
						transformOrigin: 'top left',
						transform: `translateY(${
							!this.menuIsOpen
								? '100%'
								: this.metaMenuDeltaY + 'px'
						})`,
					},
				],
				this.menuAnimation,
			);
		}
	}

	toggleLanguageMenu() {
		this.languageIsOpen = !this.languageIsOpen;

		if (this.languageIsOpen && this.menuIsOpen) {
			this.toggleMenu();
		}

		this.languageMenuElement.nativeElement.animate(
			[
				{
					transformOrigin: 'top left',
					transform: `translateY(${
						!this.languageIsOpen
							? '100%'
							: this.languageMenuDeltaY + 'px'
					})`,
				},
			],
			this.menuAnimation,
		);
	}

	switchLanguage(language: string) {
		this.languageSwitch.emit(language);
		this.toggleLanguageMenu();
	}

	closeMobileMenu(): void {
		if (this.menuIsOpen) {
			this.toggleMenu();
		}
	}
}
