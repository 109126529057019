import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';

import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { Language } from '@valk-nx/core/lib/core';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

import { form } from './form';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-newsletter',
	templateUrl: './newsletter.html',
	standalone: true,
	imports: [
		CommonModule,
		RecaptchaNotificationComponent,
		MarkdownModule,
		SharedFormlyModule,
		TranslateModule,
	],
})
export class NewsletterComponent {
	@Input() className = '';
	@Input() formFields: FormlyFieldConfig[] = form;
	@Input() newsletterSubmitting = false;
	@Input() newsletterSubmitError = false;

	@Output() formSubmit = new EventEmitter<string>();

	language: Language;

	formGroup = new FormGroup({
		email: new FormControl('', Validators.required),
	});

	newsletterSubmitted = false;

	constructor(private readonly translate: TranslateService) {
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;

		this.formGroup.controls['email'].valueChanges
			.pipe(takeUntilDestroyed())
			.subscribe(() => {
				this.newsletterSubmitted = false;
			});
	}

	onSubmit(): void {
		if (this.formGroup.valid && this.formGroup.value['email']) {
			this.formSubmit.emit(this.formGroup.value['email']);
			this.newsletterSubmitted = true;
			this.newsletterSubmitting = true;

			if (
				!GeneralHelper.isIdElementInsideViewport(
					'newsletter-submit',
					44,
				)
			) {
				GeneralHelper.scrollToElementWithId('newsletter-submit', 44);
			}
		}
	}
}
