import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicModule } from 'ng-dynamic-component';

import { ColumnsComponent } from '@valk-nx/storyblok/components/columns/src/lib/columns';
import { UspWrapperComponent } from '@valk-nx/storyblok/components/usp-wrapper/src/lib/usp-wrapper';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import {
	ISbStoryBlok,
	UspWrapper,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-introduction-section',
	templateUrl: './introduction-section.html',
	standalone: true,
	imports: [
		CommonModule,
		ColumnsComponent,
		DynamicModule,
		StoryblokDirective,
		UspWrapperComponent,
	],
})
export class StoryblokIntroductionSectionComponent extends StoryblokRootDirective {
	@Input({ required: true }) title!: ISbStoryBlok[];
	@Input({ required: true }) uspWrapper!: UspWrapper[];
}
